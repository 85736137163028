<div class="create-account-container">
  <div class="container-create-account-left">
    <app-side-image></app-side-image>
  </div>
  <div class="container-create-account-right">
    <div class="return" (click)="landing()">
      <div
        class="d-flex flex-column justify-content-center align-items-center p-2"
      >
        <!--  (click)="logout()" -->
        <a routerLinkActive="button-active" class="navbar-icon">
          <mat-icon class="text-dark m-0">keyboard_backspace</mat-icon>
        </a>
      </div>
    </div>
    <h1>Mot de passe oublié</h1>
    <div>
      <div class="inputes">
        <mat-icon class="icon"> local_post_office</mat-icon>
        <mat-form-field class="example-full-width w-100">
          <input
            matInput
            [(ngModel)]="email"
            type="email"
            placeholder="eMail"
          />
        </mat-form-field>
      </div>

      <div class="submit">
        <div class="errors">
          <li *ngFor="let error of errors" style="color: red">{{ error }}</li>
          <button class="custom-btn" (click)="updatePassword()">
            Envoyer email de vérification
          </button>
        </div>

        <p style="cursor: pointer" (click)="login()">
          <span>Avez vous déjà un compte? </span>s'identifier
        </p>
      </div>
    </div>
  </div>
</div>
