import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-conflict-calendar-dialog',
  templateUrl: './conflict-calendar-dialog.component.html',
  styleUrls: ['./conflict-calendar-dialog.component.scss'],
})
export class ConflictCalendarDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConflictCalendarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  Merge() {
    this.dialogRef.close('merge');
  }

  Replace() {
    this.dialogRef.close('replace');
  }

  Cancel() {
    this.dialogRef.close('cancel');
  }
}
