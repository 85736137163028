import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
  selector: 'app-symptome-change-dialog',
  templateUrl: './symptome-change-dialog.component.html',
  styleUrls: ['./symptome-change-dialog.component.scss'],
})
export class SymptomeChangeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SymptomeChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  valueSelected: any;
  isDate: boolean = false;

  ngOnInit() {
    this.isDate = this.data.isDate;
  }

  onCancelClick() {
    this.dialogRef.close('annuler');
  }

  onClickEvent() {
    this.dialogRef.close({
      value: this.valueSelected,
      isDate: this.isDate,
    });
  }

  onRadioChange(event: any) {
    if (this.data.selectedOption?.value === 'thrombolyse' && event === true) {
      this.isDate = true;
    } else if (this.data.selectedOption?.value === 'avis_thrombectomie' && event === true) {
      this.isDate = true;
    } else {
      this.isDate = false;
    }

    this.valueSelected = event;
  }

  onDateTimeChange(event: any) {
    this.valueSelected = event;
    this.isDate = true;
  }

  convertDateTime(dateTime: string): string {
    // Parse the input date and time string using Moment.js
    const parsedDateTime = moment(dateTime, 'DD/MM/YYYY à HH:mm');

    // Format the date and time using Moment.js
    const formattedDateTime = parsedDateTime.format('YYYY-MM-DDTHH:mm');

    return formattedDateTime;
  }
}
