import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-update-associer-balise',
  templateUrl: './patient-update-associer-balise.component.html',
  styleUrls: ['./patient-update-associer-balise.component.scss'],
})
export class PatientUpdateAssocierBaliseComponent {
  selectedBalise: any = null;
  description: string = '';
  areYouSure: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<PatientUpdateAssocierBaliseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onCancel() {
    this.dialogRef.close('cancel');
  }

  onAssocier() {
    var selected = null;
    for (var i = 0; i < this.data.balises.length; i++) {
      if (this.data.balises[i].id === this.selectedBalise) {
        selected = this.data.balises[i];
      }
    }
    if (selected.patient !== null) {
      this.areYouSure = true;
    } else {
      this.dialogRef.close({
        selectedBalise: this.selectedBalise,
        description: this.description,
      });
    }
  }

  onChangeBalise(event: any) {
    this.selectedBalise = event.value;
  }
  sure() {
    this.dialogRef.close({
      selectedBalise: this.selectedBalise,
      description: this.description,
    });
  }
}
