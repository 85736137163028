import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-form-update-delete-dialog',
  templateUrl: './patient-form-update-delete-dialog.component.html',
  styleUrls: ['./patient-form-update-delete-dialog.component.scss'],
})
export class PatientFormUpdateDeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PatientFormUpdateDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onUpdateClick() {
    this.dialogRef.close('update');
  }

  onDeleteClick() {
    this.dialogRef.close('delete');
  }
}
