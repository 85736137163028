import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  animations: [
    trigger('fade', [
      transition(':enter', [style({ opacity: 0 }), animate(250, style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate(250, style({ opacity: 0 }))]),
    ]),
  ],
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  constructor(public loader: LoadingService) {}

  ngOnInit(): void {}
}
