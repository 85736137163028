import { PatientUpdateAssocierBaliseComponent } from './../../shared/patient-update-associer-balise/patient-update-associer-balise.component';
import { PatientErrorDialogComponent } from './../../shared/patient-error-dialog/patient-error-dialog.component';
import { UserService } from './../../services/user.service';
import { BaliseService } from './../../services/balise.service';
import { ConfirmationDialogComponent } from './../../shared/confirmation-dialog/confirmation-dialog.component';
import { PatientFormUpdateDeleteDialogComponent } from './../../shared/patient-form-update-delete-dialog/patient-form-update-delete-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from './../../services/patient.service';
import { ExportDialogComponent } from './../../shared/export-dialog/export-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { formDropDownoptions } from '../../../constants/form-dropdown-options';
import { Router } from '@angular/router';
import { SymptomeChangeDialogComponent } from 'src/app/shared/symptome-change-dialog/symptome-change-dialog.component';

@Component({
  selector: 'app-patient-add',
  templateUrl: './patient-add.component.html',
  styleUrls: ['./patient-add.component.scss'],
})
export class PatientAddComponent {
  patient: any;
  loading: boolean = true;
  symptoms: any[] = [];
  formDropDownoptions = formDropDownoptions;
  selectedOption: any = null;
  showDateTimePicker = false;
  nris: any[] = [];
  balises: any[] = [];
  user: any = null;
  selectedNri: any = null;
  firstName: string = '';
  lastName: string = '';
  birthday: string = '';
  opposition: boolean = false;
  patientId: string = '';
  baliseId: any = null;
  selectedBalise: any = null;
  isTacosDisabled: boolean = true;

  constructor(
    public dialog: MatDialog,
    private patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private baliseService: BaliseService,
    private userService: UserService,
  ) {}

  deleteDossierPatient() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Information',
        message: 'Voulez-vous supprimer ce dossier patient ?',
        actionButton: 'Supprimer',
        cancelButton: 'Annuler',
        action: 'delete',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'annuler') {
      } else if (result === 'delete') {
        this.patientService.deletePatient(this.patient.id).subscribe((data) => {
          this.router.navigate(['/home/patient']);
        });
      }
    });
  }

  onSymptomeClick(symptome: any) {
    const userHospital: string = this.user?.nri ? 'nri' : 'unv';

    if (symptome.hospital !== userHospital) {
      const dialogRef = this.dialog.open(PatientErrorDialogComponent, {
        width: '500px',
        data: {
          title: 'Erreur',
          description: 'Vous ne pouvez pas modifier cette option',
        },
      });
      return;
    }

    const dialogRef = this.dialog.open(PatientFormUpdateDeleteDialogComponent, {
      width: '500px',
      data: {
        title: symptome.title,
        description: symptome.value,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'update') {
        let find = this.formDropDownoptions.find((item) => item.name === symptome.title);

        if (find) {
          this.selectedOption = {
            name: find.name,
            value: find.value,
            symptome: symptome,
          };
        }

        const dialogRef = this.dialog.open(SymptomeChangeDialogComponent, {
          width: '500px',
          data: {
            title: 'Information',
            message: 'Voulez-vous supprimer ce dossier patient ?',
            selectedOption: this.selectedOption,
            isDate: this.checkIfDateTimePickShow(),
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'annuler') {
            return;
          } else {
            if (result.isDate) {
              this.onDateTimeChange(result.value);
            } else {
              this.onRadioChange(result.value);
            }
          }
        });
      } else if (result === 'delete') {
        this.selectedOption = null;

        this.symptoms = this.symptoms.filter((item) => item.title !== symptome.title);
      }
    });
  }

  ngOnInit() {
    this.patientService.getNris().subscribe((nriData) => {
      this.userService.getCurrentUser().subscribe((userData) => {
        this.baliseService.getBalises().subscribe((baliseData) => {
          this.user = userData;

          // this.birthdate default date is 16 juin at this fucking time
          const sixteenJuinBefore73Years = new Date(1947, 5, 16, 0, 0, 0, 0);
          this.birthday = moment(sixteenJuinBefore73Years).format('YYYY-MM-DD');
          const emptyNri = {
            id: null,
            name: 'Hôpital lié',
          };

          const myNri = {
            id: nriData?.id,
            name: nriData?.name,
          };

          this.nris = [emptyNri, myNri];

          if (nriData?.nris) {
            this.nris = [
              ...this.nris,
              ...nriData?.nris?.map((nri: any) => {
                return {
                  id: nri.id,
                  name: nri.name,
                };
              }),
            ];
          }

          const emptyBalise = {
            id: null,
            name: 'Sélectionner une balise',
          };

          this.balises = [emptyBalise];

          if (baliseData) {
            this.balises = [
              ...this.balises,
              ...baliseData.map((balise: any) => {
                return {
                  id: balise.id,
                  name: balise.name,
                };
              }),
            ];
          }

          this.loading = false;
        });
      });
    });
  }

  toggleOpposition() {
    this.opposition = !this.opposition;
  }

  onOptionChange(event: any) {
    this.selectedOption = event.value;

    // get the object from symptoms array
    const find = this.symptoms.find(
      (symptom) => symptom.title.toLowerCase() === this.selectedOption.name.toLowerCase(),
    );
    if (find) {
      this.selectedOption.symptome = find;
    } else {
      this.selectedOption.symptome = {
        dateRenseignement: null,
        hospital: null,
        title: null,
        user: null,
        value: null,
      };
    }

    const dialogRef = this.dialog.open(SymptomeChangeDialogComponent, {
      width: '500px',
      data: {
        title: 'Information',
        message: 'Voulez-vous supprimer ce dossier patient ?',
        selectedOption: this.selectedOption,
        isDate: this.checkIfDateTimePickShow(),
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'annuler') {
        return;
      } else {
        if (result.isDate) {
          this.onDateTimeChange(result.value);
        } else {
          this.onRadioChange(result.value);
        }
      }
    });
  }
  annule() {
    this.router.navigateByUrl('/home/patient');
  }
  onRadioChange(value: any) {
    if (value === undefined || value === null) {
      this.symptoms = this.symptoms.filter((symptom) => symptom.title !== this.selectedOption.name);
      return;
    }

    this.selectedOption.symptome.value = value;
    this.selectedOption.symptome.dateRenseignement = moment().format('DD/MM/YYYY à HH:mm');
    this.selectedOption.symptome.user = this.user.firstName + ' ' + this.user.lastName;
    this.selectedOption.symptome.hospital = this.user.nri ? 'nri' : 'unv';
    formDropDownoptions.forEach((option) => {
      if (option.name === this.selectedOption.name) {
        this.selectedOption.symptome.title = option.name;
      }
    });

    let find = this.symptoms.find(
      (symptom) => symptom.title.toLowerCase() === this.selectedOption.name.toLowerCase(),
    );

    if (find) {
      find = this.selectedOption.symptome;
    } else {
      this.symptoms.push(this.selectedOption.symptome);
    }
  }

  onDateTimeChange(event: any) {
    const selectedDate = event.target.value;

    if (moment(selectedDate).isAfter(moment())) {
      const dialogRef = this.dialog.open(PatientErrorDialogComponent, {
        width: '500px',
        data: {
          title: 'Erreur',
          description: 'Vous ne pouvez pas renseigner une date future',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'cancel') {
        }
      });

      return;
    }

    if (selectedDate === undefined || selectedDate === null) {
      this.symptoms = this.symptoms.filter((symptom) => symptom.title !== this.selectedOption.name);
      return;
    }

    this.selectedOption.symptome.value = this.convertDateTimeToFormat(selectedDate);
    this.selectedOption.symptome.dateRenseignement = moment().format('DD/MM/YYYY à HH:mm');
    this.selectedOption.symptome.user = this.user.firstName + ' ' + this.user.lastName;
    this.selectedOption.symptome.hospital = this.user.nri ? 'nri' : 'unv';
    formDropDownoptions.forEach((option) => {
      if (option.name === this.selectedOption.name) {
        this.selectedOption.symptome.title = option.name;
      }
    });

    let find = this.symptoms.find(
      (symptom) => symptom.title.toLowerCase() === this.selectedOption.name.toLowerCase(),
    );

    if (find) {
      find = this.selectedOption.symptome;
    } else {
      this.symptoms.push(this.selectedOption.symptome);
    }
  }

  convertDateTime(dateTime: string): string {
    // Parse the input date and time string using Moment.js
    const parsedDateTime = moment(dateTime, 'DD/MM/YYYY à HH:mm');

    // Format the date and time using Moment.js
    const formattedDateTime = parsedDateTime.format('YYYY-MM-DDTHH:mm');
    return formattedDateTime;
  }

  convertDateTimeToFormat(dateTime: string): string {
    // Parse the input date and time string using Moment.js
    const parsedDateTime = moment(dateTime, 'YYYY-MM-DDTHH:mm');

    // Format the date and time using Moment.js
    const formattedDateTime = parsedDateTime.format('DD/MM/YYYY à HH:mm');

    return formattedDateTime;
  }

  checkIfDateTimePickShow() {
    if (this.selectedOption?.value == null || this.selectedOption?.value == undefined) {
      return false;
    }

    if (this.selectedOption?.value == 'avc') {
      return false;
    }
    if (this.selectedOption?.value == 'thrombolyse') {
      if (this.selectedOption?.symptome?.value?.length > 10) {
        return true;
      }
      if (this.selectedOption?.symptome?.value == true) {
        return true;
      }
      return false;
      return false;
    }
    if (this.selectedOption?.value == 'avis_thrombectomie') {
      if (this.selectedOption?.symptome?.value?.length > 10) {
        return true;
      }
      if (this.selectedOption?.symptome?.value == true) {
        return true;
      }
      return false;
    }
    return true;
  }

  addPatient() {
    let isError: any = null;

    this.symptoms.forEach((symptom) => {
      if (symptom.value === null || symptom.value === undefined) {
        isError = {
          title: 'Erreur ' + symptom.title,
          description: `La valeur du ${symptom.title} est vide`,
        };
      }
      if (symptom.title === 'Thrombolyse' && symptom.value === true) {
        isError = {
          title: 'Erreur ' + symptom.title,
          description: `Veiullez renseigner la date de ${symptom.title}`,
        };
      } else if (symptom.title === 'Avis thrombectomie' && symptom.value === true) {
        isError = {
          title: 'Erreur ' + symptom.title,
          description: `Veiullez renseigner la date de ${symptom.title}`,
        };
      }
    });

    if (isError) {
      const dialogRef = this.dialog.open(PatientErrorDialogComponent, {
        width: '500px',
        data: {
          title: isError.title,
          description: isError.description,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'cancel') {
        }
      });
      return;
    }

    this.loading = true;

    let options: any = {};

    this.symptoms.forEach((symptom) => {
      formDropDownoptions.forEach((option) => {
        if (option.name.toLowerCase() === symptom.title.toLowerCase()) {
          const convertedDateTime = this.convertDateTime(symptom.value);
          const isoString = convertedDateTime;
          if (isoString != null) {
            symptom.value = isoString;
          }

          const convertedDateTimeRenseignement = this.convertDateTime(symptom.dateRenseignement);

          const isoStringRenseignement = convertedDateTimeRenseignement;

          if (isoStringRenseignement != null) {
            symptom.dateRenseignement = isoStringRenseignement;
          }

          if (
            option.value === 'thrombolyse' &&
            (symptom.value === true || symptom.value.length > 10)
          ) {
            options['thrombolyse_date'] = {
              dateRenseignement: symptom.dateRenseignement,
              hospital: symptom.hospital,
              user: symptom.user,
              value: symptom.value,
            };

            options['thrombolyse'] = {
              dateRenseignement: symptom.dateRenseignement,
              hospital: symptom.hospital,
              user: symptom.user,
              value: true,
            };
          } else if (
            option.value === 'avis_thrombectomie' &&
            (symptom.value === true || symptom.value.length > 10)
          ) {
            options['avis_thrombectomie_date'] = {
              dateRenseignement: symptom.dateRenseignement,
              hospital: symptom.hospital,
              user: symptom.user,
              value: symptom.value,
            };

            options['avis_thrombectomie'] = {
              dateRenseignement: symptom.dateRenseignement,
              hospital: symptom.hospital,
              user: symptom.user,
              value: true,
            };
          } else {
            options[option.value] = {
              dateRenseignement: symptom.dateRenseignement,
              hospital: symptom.hospital,
              user: symptom.user,
              value: symptom.value,
            };
          }
        }
      });
    });

    const birthdayIsoString = moment(this.birthday).toISOString();

    const encodedOptions = JSON.stringify(options);

    let body = {
      firstname: this.firstName.toString(),
      lastname: this.lastName.toString(),
      birthday: birthdayIsoString,
      opposition: this.opposition.toString(),
      options: encodedOptions,
      baliseId: this.selectedBalise,
      nriId: this.selectedNri,
      description: this.firstName.toString(),
    };

    this.patientService.addPatient(body).subscribe((data) => {
      this.loading = false;
      this.router.navigate(['/home/patient']);
    });
  }

  onChangeSelectedBalise(event: any) {
    this.selectedBalise = event.value;

    if (this.selectedBalise != null) {
      this.selectedNri = null;
    }
  }

  onChangeSelectedNRI(event: any) {
    this.selectedNri = event.value;
  }
}
