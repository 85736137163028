import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root',
})
export class BaliseService {
  constructor(
    private http: HttpClient,
    private socket: Socket,
  ) {}

  getBalises() {
    return this.http.get<any>('https://romain.care/api/balise/all', {
      headers: { skip: 'true' },
    });
  }

  public onBalise() {
    return this.socket.fromEvent<any>('balise');
  }

  public onBaliseEnd() {
    return this.socket.fromEvent<any>('balise-end');
  }

  public setTransportType(mode: boolean, baliseId: number) {
    return this.http.patch<any>(`https://romain.care/api/balise/${baliseId}`, {
      land: mode,
    });
  }

  public getTravelTime(origin: any, destination: any) {
    const matrix = new google.maps.DistanceMatrixService();
    return new Promise((resolve, reject) => {
      matrix.getDistanceMatrix(
        {
          origins: [new google.maps.LatLng(origin.lat, origin.lng)],
          destinations: [new google.maps.LatLng(destination.lat, destination.lng)],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          durationInTraffic: true,
        },
        (response, status) => {
          if (status !== google.maps.DistanceMatrixStatus.OK) {
            console.log('Error:', status);
            reject(status);
          } else {
            if (response.rows[0].elements[0].status === 'ZERO_RESULTS') {
              reject('No results found');
            }
            resolve(response);
          }
        },
      );
    });
  }
}
