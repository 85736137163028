<div class="container-fluid">
    <div>
        <div class="d-flex flex-row justify-content-between">
            <h1><b>Notifications</b></h1>
            <text-button [outlined]="true" text="Marquer tout comme lu"
                [disabled]="notificationService.notifications.length==0" (click)="clearNotifications()" />
        </div>
        <div *ngIf="notificationService.notifications.length==0;else notEmpty">il n'y a pas de notifications
            pour le moment</div>
        <ng-template #notEmpty>
            <div class="notifications-list">
                <notification-card *ngFor="let notification of notificationService.notifications" [notification]="notification"></notification-card>
            </div>
        </ng-template>
    </div>
</div>