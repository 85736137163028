<mat-card
  (click)="redirectToBalise(notification)"
  class="d-flex flex-row justify-content-between align-items-center my-2 px-4 py-2"
  [ngClass]="!notification?.read ? 'unreaded' : 'readed'"
>
  <h3 class="mb-0">{{ notification?.message }}</h3>
  <div
    class="d-flex flex-row justify-content-between align-items-center my-2 text-secondary"
  >
    <h4 class="mb-0">
      {{ formatNotificationTime(notification?.date) }}
    </h4>
    <button *ngIf="!notification?.read" mat-icon-button (click)="markAsRead()">
      <mat-icon> visibility </mat-icon>
    </button>
  </div>
</mat-card>
