import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  @Input() icon?: String;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Input() disabled?: boolean;
  @Input() outlined?: boolean = false;
  @Input() label?: string;

  constructor() {}

  click() {
    this.onClick.emit();
  }
}
