import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookieServiceService {
  constructor(private cookieService: CookieService) {}

  getCookie(key: string): string {
    return this.cookieService.get(key);
  }

  checkCookie(key: string): boolean {
    return this.cookieService.check(key);
  }
}
