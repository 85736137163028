import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InputesService {
  constructor() {}
  loginForm: any = {
    email: '',
    password: '',
  };
  signupForm: any = {
    firstName: '',
    lastName: '',
    gender: 'male',
    email: '',
    password: '',
    phone: '',
    RPPS: '',
  };
  profilForm: any = {};
}
