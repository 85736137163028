<div
  class="container-home"
>
  <div class="app-header">
    <app-navbar></app-navbar>
  </div>
  <div class="app-body">
    <div class="app-side-bar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="app-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
