import { Component, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private socket: Socket,
    private messageServie: MessageService,
  ) {}

  ngOnInit(): void {
    this.socket.ioSocket['auth'] = {
      authorization: 'Bearer ' + this.authService.getToken(),
    };
    this.socket.connect();
    this.socket.on('error', (err: any) => {
      console.log(err);
      this.messageServie.showMessage('Connection failed to server');
    });
    this.socket.on('connect_error', () =>
      this.messageServie.showMessage('Connection failed to server'),
    );
    this.socket.on('connect_failed', () =>
      this.messageServie.showMessage('Connection failed to server'),
    );
    this.socket.on('disconnect', () =>
      this.messageServie.showMessage('Connection failed to server'),
    );
  }
}
