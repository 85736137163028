import { Component } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})

export class ContactsComponent {
  collegsList: any;
  adminsList: any;
  curentUser : any 
  constructor(
    private cs: ContactService,
    private dialog: MatDialog,
    private us : UserService
  ) {}
  ngOnInit(): void {
    this.cs.getAdmins().subscribe((res: any) => {
      this.adminsList = res;
    });
    this.cs.getCollegs().subscribe((res) => {
      this.collegsList = res;
    });
    this.us.getCurrentUser().subscribe((res: any) => {
      this.curentUser = res;
    });
  }

  contact(way: any, number: any) {
    if (way === 'phone') {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '400px',
        height: '200px',

        data: { use: 'contact', number },
      });
    }
  }
  Eye(user: any) {
    if(user.nri !== null ){
      if(this.curentUser.nri !== null && this.curentUser.nri.name === user.nri.name){
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '900px',
          height: '800px',
          data: { use: 'contactEye', user, id: user.id },
        });
      } else {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '500px',
          height: '700px',
          data: { use: 'contactEye2', user, id: user.id },
        });
      }
    } else if(user.unv !== null) {
      if(this.curentUser.unv !== null && this.curentUser.unv.name === user.unv.name){
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '900px',
          height: '800px',
          data: { use: 'contactEye', user, id: user.id },
        });
      } else {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '500px',
          height: '700px',
          data: { use: 'contactEye2', user, id: user.id },
        });
      }
    }
    
  }
  sendMail(data: any) {
    window.open(
      'mailto:' + data.email + '?cc=' + '' + '&subject=' + 'Contact Romain' + '&body=' + '',
    );
  }
}
