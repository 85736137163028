<div
  class="navbar-bg-color d-flex flex-row justify-content-between align-items-center py-2 px-3"
>
  <span class="logo">
    <img src="../../../assets/logo_romain.PNG" class="img-fluid mb-0" />
  </span>
  <div class="d-flex flex-row justify-content-center">
    <div
      *ngIf="user?.unv === null"
      class="d-flex flex-column justify-content-center align-items-center p-2"
    >
      <a
        routerLinkActive="button-active"
        class="navbar-icon"
        routerLink="notification"
      >
        <mat-icon class="text-dark m-0">notifications_active</mat-icon>

        <span
          class="custom-badge"
          *ngIf="notificationService.howManyUnread() > 0"
          [matBadge]="notificationService.howManyUnread()"
          matBadgeSize="medium"
          matBadgeColor="warn"
        ></span>
      </a>
      <div class="button-title">Notifications</div>
    </div>
    <div
      class="d-flex flex-column justify-content-center align-items-center p-2"
    >
      <!--  (click)="logout()" -->
      <a
        (click)="disconnect()"
        routerLinkActive="button-active"
        class="navbar-icon"
      >
        <mat-icon class="text-dark m-0">logout</mat-icon>
      </a>
      <div class="button-title">Déconnexion</div>
    </div>
  </div>
</div>
