import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss'],
})
export class TextButtonComponent {
  @Input() text?: String;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Input() disabled?: boolean;
  @Input() outlined?: boolean = false;
  @Input() icon?: string;
  @Input() routerLink?: string;

  constructor() {}

  click() {
    this.onClick.emit();
  }
}

export enum ButtonType {
  filled,
  outlined,
}
