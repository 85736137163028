import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
  ) {}

  login(user: any) {
    return this.http.post('https://romain.care/api/auth/user', user);
  }

  logoutecps() {
    return this.http.post('https://romain.care/api/auth/user/logout', {});
  }

  getUser() {
    return this.http.get('https://romain.care/api/auth/user');
  }
  finalStepEcps(rpps: string) {
    return this.http.post('https://romain.care/api/user/findbyrpps', { RPPS: rpps });
  }
  loginEcps() {
    return this.http.get('https://romain.care/api/auth/login');
  }
  signup(user: any) {
    return this.http.post('https://romain.care/api/user', user);
  }
  public logout(): boolean {
    localStorage.removeItem('token');
    return true;
  }

  public isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  public isTokenValid(): boolean {
    try {
      return !this.jwtHelper.isTokenExpired(this.getToken() || '');
    } catch (error) {
      return false;
    }
  }

  public getTokenDecoded() {
    return this.jwtHelper.decodeToken(this.getToken() || '');
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public getRole(): string | null {
    return this.getTokenDecoded().role;
  }
}
