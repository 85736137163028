import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { InputesService } from 'src/app/services/inputes.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  isPasswordVisible = false;

  constructor(private is: InputesService) {}
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  @Input() type!: string;
  @Input() name!: string;
  @Input() text!: string;
  @Input() dis?: boolean;
  @Input() isRequired: boolean = true;
  @Input() value!: string;
  @Input() placeholder!: string;

  giveValue(event: any) {
    this.is.loginForm[this.name] = event.target.value;
    this.is.signupForm[this.name] = event.target.value;
    this.is.profilForm[this.name] = event.target.value;
  }
}
