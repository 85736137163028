<h1>Liste des Balises</h1>
<div class="balise-map-list" *ngIf="!(balises.length > 0)">
  <h2 class="mb-0" style="color: #eb5d2b">Aucune balise trouvée !</h2>
</div>
<div [ngClass]="'balise'">
  <div class="balise-map-list" *ngIf="balises.length > 0">
    <div *ngIf="balises.length > 0" style="margin-bottom: 10px" class="d-flex flex-column gap-2">
      <mat-expansion-panel
        *ngFor="let balise of balises; let i = index"
        [expanded]="selectedBaliseTile === i"
      >
        <mat-expansion-panel-header (click)="setSelectedBaliseTile(i)">
          <div style="display: grid; grid-template-rows: 1fr 1fr">
            <mat-panel-title>
              <mat-icon> dashboard </mat-icon> {{ balise.name ?? 'Undefined' }}
            </mat-panel-title>
            <mat-panel-description>{{
              travelsTime.get(balise.id)?.description
            }}</mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        <hr class="m-0 p-0" />
        <div class="d-flex flex-column py-2">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <p>
              Batterie: <span>{{ balise?.batterie }}%</span>
            </p>
            <div class="d-flex flex-column justify-content-between align-items-center">
              <small>Transport</small>
              <div class="d-flex flex-row justify-content-center">
                <button (click)="switchTransportType(i, true)" mat-icon-button>
                  <img [src]="balise?.land ? 'assets/van_filled.svg' : 'assets/van.svg'" />
                </button>
                <div class="vertical-divider m-2"></div>
                <button (click)="switchTransportType(i, false)" mat-icon-button class="m-0 p-0">
                  <img
                    [src]="!balise?.land ? 'assets/helicopter_filled.svg' : 'assets/helicopter.svg'"
                  />
                </button>
              </div>
            </div>
          </div>
          <!-- travelsTime.get(balise.id)?.description -->
          <p>
            Début De Transfert:
            <span>{{
              formatTransfertStart(balise?.history, travelsTime.get(balise.id)?.status!)
            }}</span>
          </p>
          <p *ngIf="balise?.nri">
            Destination:
            <span>
              {{ balise?.nri?.name }}
            </span>
          </p>
          <p *ngIf="travelsTime.get(balise.id)?.status === baliseStatusTypes.COMMUNICATION_ERROR">
            Derniére activité:
            <span>{{ formatLatestActivity(balise?.updatedAt) }}</span>
          </p>
          <!-- <p *ngIf="balise?.unv">Depart: <span>{{balise?.unv?.name}}</span></p> -->
          <div class="d-flex flex-row justify-content-center align-items-center">
            <text-button
              (click)="redirectPatient(balise?.patient?.id ?? null)"
              [outlined]="true"
              [text]="balise?.patient ? 'Dossier patient' : 'Associer patient'"
            ></text-button>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="balise-map">
    <agm-map
      [latitude]="userPosition?.lat"
      [longitude]="userPosition?.lng"
      [disableDefaultUI]="true"
      [usePanning]="false"
      [zoom]="10"
      [mapTypeControl]="true"
      (mapReady)="mapReady($event)"
      [zoomControl]="false"
    >
      <div *ngIf="balises.length >= 0; else emptyActifBalises">
        <div *ngFor="let balise of balises; let i = index">
          <agm-marker
            [latitude]="
              hasPosition(balise?.position)
                ? balise?.position?.latitude
                : balise?.unv?.position?.latitude
            "
            [longitude]="
              hasPosition(balise?.position)
                ? balise?.position?.longitude
                : balise?.unv?.position?.longitude
            "
            [iconUrl]="hasPosition(balise?.position) ? iconUrl : unvIconUrl"
          >
            <agm-info-window
              [isOpen]="selectedBaliseTile === i"
              [disableAutoPan]="false"
              #infoWindow
            >
              <b>
                {{ hasPosition(balise?.position) ? balise?.name : balise?.unv?.name }}
              </b>
            </agm-info-window>
            <agm-direction
              *ngIf="selectedBaliseTile !== -1"
              [renderOptions]="{
                suppressMarkers: true,
                preserveViewport: true
              }"
              [markerOptions]="markerOptions"
              [origin]="origin"
              [destination]="destination"
            >
            </agm-direction>
          </agm-marker>
          <!-- Balise UNV -->
          <agm-marker
            *ngIf="balise?.unv"
            [latitude]="balise?.unv?.position?.latitude"
            [longitude]="balise?.unv?.position?.longitude"
            [iconUrl]="unvIconUrl"
          >
            <agm-info-window
              [isOpen]="selectedBaliseTile === i"
              [disableAutoPan]="false"
              #infoWindow
            >
              <b>
                {{ balise?.unv?.name }}
              </b>
            </agm-info-window>
          </agm-marker>
          <!-- Balise NRI -->
          <agm-marker
            *ngIf="balise?.nri"
            [latitude]="balise?.nri?.position?.latitude"
            [longitude]="balise?.nri?.position?.longitude"
            [iconUrl]="nriIconUrl"
          >
            <agm-info-window
              [isOpen]="selectedBaliseTile === i"
              [disableAutoPan]="false"
              #infoWindow
            >
              <b>
                {{ balise?.nri?.name }}
              </b>
            </agm-info-window>
          </agm-marker>
        </div>
        <agm-marker
          *ngIf="selectedBaliseTile !== -1"
          [latitude]="balises[selectedBaliseTile].unv?.position?.latitude"
          [longitude]="balises[selectedBaliseTile].unv?.position?.longitude"
          [iconUrl]="unvIconUrl"
        >
          <agm-info-window [disableAutoPan]="false" #infoWindow>
            <b>
              {{ balises[selectedBaliseTile].unv?.name }}
            </b>
          </agm-info-window>
        </agm-marker>
      </div>
      <ng-template #emptyActifBalises>
        <agm-marker
          [latitude]="userPosition?.lat"
          [longitude]="userPosition?.lng"
          [markerDraggable]="false"
          [iconUrl]="userMarkerOptions"
        ></agm-marker>
      </ng-template>
    </agm-map>
  </div>
</div>
