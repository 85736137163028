import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CookieServiceService } from 'src/app/services/cookie-service.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent {
  constructor(
    private route: Router,
    private active: ActivatedRoute,
    private auth: AuthService,
    private cookieService: CookieServiceService,
  ) {}
  ngOnInit(): void {
    this.active.queryParamMap.subscribe((params: any) => {
      const token = params.get('token');
      localStorage.setItem('token', token);
      var h = localStorage.getItem('token')?.length;
      if (h) {
        if (!this.isMobile()) {
          this.route.navigateByUrl('/home/balises');
        }
      }
    });
  }
  isMobile() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android|webos|iphone|ipad|ipod|blackberry|windows phone/i.test(userAgent);
  }
  navigate() {
    this.route.navigate(['/login']);
  }
  ecps() {
    location.replace('https://romain.care/api/auth/login');
  }
  signup() {
    this.route.navigate(['/signup']);
  }
}
