import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { InputesService } from 'src/app/services/inputes.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent {
  constructor(
    private auth: AuthService,
    private is: InputesService,
    private dialog: MatDialog,
    private router: Router,
  ) {}
  list: any = [
    {
      icon: 'description',
      type: 'email',
      name: 'RPPS',
      text: 'n°RPPS',
      placeholder: 'n°RPPS',
    },
    {
      icon: 'person',
      type: 'text',
      name: 'lastName',
      text: 'Nom',
      placeholder: 'Nom',
    },
    {
      icon: 'person',
      type: 'text',
      name: 'firstName',
      text: 'Prénom',
      placeholder: 'Prénom',
    },
    {
      icon: 'phone',
      type: 'number',
      name: 'phone',
      text: 'Numéro de téléphone',
      placeholder: 'Numéro de téléphone',
    },
    {
      icon: 'local_post_office',
      type: 'email',
      name: 'email',
      text: 'eMail',
      placeholder: 'eMail',
    },
  ];
  password: string = '';
  passwordVisible = false;
  ngOnInit(): void {}
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
  errors: any = [];

  signup() {
    this.is.signupForm.email = this.is.signupForm.email.toLocaleLowerCase();
    this.is.signupForm.password = this.password;
    if (this.validatePassword(this.password, this.is.signupForm)) {
      this.auth.signup(this.is.signupForm).subscribe(
        (res) => {
          const dialogRef = this.dialog.open(DialogComponent, {
            width: '600px',
            height: '450px',
            data: { use: 'waiting' },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if(result.data === "login"){
                this.router.navigateByUrl("/login")
            }
          })
        },
        (err) => {
          this.errors.push(err.message);
        },
      );
    }
  }
  login() {
    this.router.navigateByUrl('/login');
  }
  landing() {
    this.router.navigateByUrl('/');
  }
  validatePassword(password: any, data: any) {
    console.log(password , data)
    this.errors = [];
    const minLength = 8;
    const maxLength = 20;
    const regexUpperCase = /[A-Z]/;
    const regexLowerCase = /[a-z]/;
    const regexNumber = /[0-9]/;
    const regexSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (password.length < minLength) {
      this.errors.push("Mot de passe doit être d'au moins 8 caractères");
    }
    //  if (!regexUpperCase.test(password)) {
    //    this.errors.push("Le mot de passe doit contenir une lettre majuscule")

    //  }
    //  if (!regexLowerCase.test(password)) {
    //    this.errors.push("le mot de passe doit contenir une lettre minuscule")
    //  }
    //  if (!regexNumber.test(password)) {
    //    this.errors.push("le mot de passe doit contenir un nombre")

    //  }
    //  if (!regexSpecial.test(password)) {
    //    this.errors.push("Le mot de passe doit contenir un caractère spécial")
    //  }
    if (data.phone.toString().length !== 10) {
      this.errors.push('le numéro de téléphone doit contenir 10 caractères');
    }
    const re = /\S+@\S+\.\S+/;
    if (!re.test(data.email)) {
      this.errors.push('eMail non valide');
    }

    for (var key in data) {
      if (typeof data[key] === 'string') {
        data[key] = data[key].trim();
        if (data[key].length === 0) {
          this.errors.push('toutes les entrées sont requises');
          break;
        }
      }
    }
    if (this.errors.length) {
      return false;
    } else {
      return true;
    }
  }
}
