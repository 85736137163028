<div class="example-form">
  <mat-form-field class="example-full-width w-100">
    <mat-label>{{ text }}</mat-label>
    <input
      (change)="giveValue($event)"
      type="{{ type }}"
      matInput
      [formControl]="emailFormControl"
      placeholder="{{ placeholder }}"
      value="{{ value }}"
      [readonly]="dis ?? false"
      [required]="isRequired"
    />
  </mat-form-field>
</div>
