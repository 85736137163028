import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() text!: string;
  @Input() icon!: string;
  @Input() iconColor!: string;
  @Input() class!: string;
  @Input() borderRadius!: string;
  @Input() id!: string;
  @Input() paddingLeft!: string;
  @Input() paddingRight!: string;
  @Input() paddingTop!: string;
  @Input() paddingBottom!: string;
}
