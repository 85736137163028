<div class="create-account-container">
  <div class="container-create-account-left">
    <app-side2></app-side2>
  </div>
  <div class="container-create-account-right">
    <div class="return"></div>
    <h1>créer un nouveau mot de passe</h1>
    <div>
      <div class="inputes">
        <mat-icon class="icon"> local_post_office</mat-icon>
        <mat-form-field class="example-full-width w-100">
          <input
            matInput
            [(ngModel)]="email"
            type="email"
            placeholder="eMail"
          />
        </mat-form-field>
      </div>
      <div class="inputes">
        <mat-icon class="icon"> https</mat-icon>
        <mat-form-field class="example-full-width w-100">
          <input
            matInput
            [(ngModel)]="password"
            [type]="passwordVisible ? 'text' : 'password'"
            placeholder="Mot de passe"
          />
          <mat-icon matSuffix (click)="togglePasswordVisibility()">{{
            passwordVisible ? "visibility_off" : "visibility"
          }}</mat-icon>
        </mat-form-field>
      </div>
      <div class="inputes">
        <mat-icon class="icon"> https</mat-icon>
        <mat-form-field class="example-full-width w-100">
          <input
            matInput
            [(ngModel)]="password2"
            [type]="passwordVisible2 ? 'text' : 'password'"
            placeholder="Mot de passe"
          />
          <mat-icon matSuffix (click)="togglePasswordVisibility2()">{{
            passwordVisible2 ? "visibility_off" : "visibility"
          }}</mat-icon>
        </mat-form-field>
      </div>
      <div class="submit">
        <div class="errors">
          <li *ngFor="let error of errors" style="color: red">{{ error }}</li>
        </div>
        <app-button
          (click)="updatePassword()"
          borderRadius="8px"
          class="white"
          text="créer un nouveau mot de passe
          "
          id="colorTextOrange"
          paddingLeft="40px"
          paddingRight="40px"
          paddingTop="12px"
          paddingBottom="12px"
        ></app-button>
      </div>
    </div>
  </div>
</div>
