import { Component } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  constructor(public notificationService: NotificationService) {}

  clearNotifications() {
    this.notificationService.clearNotifications().subscribe(
      (res) => {
        this.notificationService.getNotifications();
        this.notificationService.notifications.map((notification: any) => {
          notification.read = true;
        });
      },
      (error) => {
        console.log(error);
      },
    );
  }
}
