<div class="container">
  <h1>Exporter le dossier</h1>

  <div class="container-share">
    <div class="container-column-email" (click)="onSendEmail()">
      <mat-icon>email</mat-icon>
      <span> Envoyer Par Mail </span>
    </div>
    <div class="divider"></div>
    <div class="container-column-print" (click)="onPrint()">
      <mat-icon>print</mat-icon>
      <span>Imprimer</span>
    </div>
  </div>

  <div class="form-actions-buttons-container">
    <text-button
      text="Télecharger"
      [outlined]="false"
      (click)="onDownload()"
    ></text-button>

    <text-button
      text="Annuler"
      [outlined]="true"
      (click)="onCancel()"
    ></text-button>
  </div>
</div>
