<h1>Profil</h1>
<div class="profilContainer" style="width: 100%">
  <div style="width: 100%">
    <h3>Connexion</h3>
    <div style="display: flex; gap: 15em">
      <div style="display: flex; width: 30%">
        <mat-icon> email</mat-icon>
        <app-input
          type="email"
          name="email"
          text="eMail"
          placeholder="eMail"
          value="{{ user?.email }}"
        ></app-input>
      </div>
      <div style="display: flex; width: 30%">
        <mat-icon>sensor_occupied</mat-icon>
        <app-input
          value="{{ user?.RPPS }}"
          type="text"
          name="RPPS"
          text="n°RPPS"
          placeholder="n°RPPS"
        ></app-input>
      </div>
    </div>
  </div>
  <div>
    <div class="calendar-title">
      <h3>Mon Calendrier</h3>
      <mat-icon
        style="color: #eb5d2b; margin: 0; cursor: pointer; justify-self: end"
        (click)="addEvent()"
        >calendar_today</mat-icon
      >
    </div>

    <div class="calendar-container">
      <div class="callender-profile">
        <full-calendar [options]="calendarOptions"></full-calendar>
      </div>
      <div *ngIf="dayEvents?.length > 0" class="day-events">
        <div class="day">
          <h2>{{ dayEvents[0]?.day?.slice(0, 2) }}</h2>
        </div>
        <div
          (click)="eventClick(e)"
          class="day-event"
          *ngFor="let e of dayEvents"
        >
          <h2>de {{ e?.trimedstart }} à {{ e?.trimedend }}</h2>
        </div>
      </div>
      <div *ngIf="dayEvents?.length === 0" class="day-events">
        <div class="day">
          <h2>{{ tdy.slice(0, 2) }}</h2>
        </div>
        <div>
          <h2>Pas d'événements</h2>
        </div>
      </div>
    </div>
  </div>

  <div>
    <h3>Informations Personnelles</h3>
    <div style="display: flex; flex-direction: row; gap: 15em">
      <div style="display: flex; width: 30%">
        <mat-icon> person</mat-icon>
        <app-input
          value="{{ user?.lastName }}"
          type="text"
          name="lastName"
          text="Nom"
          placeholder="Nom"
        ></app-input>
      </div>
      <div style="display: flex; width: 30%">
        <mat-icon>person</mat-icon>
        <app-input
          type="text"
          value="{{ user?.firstName }}"
          name="firstName"
          text="Prénom"
          placeholder="Prénom"
        ></app-input>
      </div>
    </div>
    <div style="display: flex; gap: 15em">
      <div style="display: flex; width: 30%">
        <mat-icon> phone</mat-icon>
        <app-input
          value="{{ user?.phone }}"
          type="text"
          name="phone"
          text="Numéro de téléphone"
          placeholder="Numéro de téléphone"
        ></app-input>
      </div>
      <div style="display: flex; width: 30%">
        <mat-icon> local_hospital</mat-icon>
        <app-input
          value="{{ value }}"
          type="text"
          name="phone"
          text="Hôpital lié"
          placeholder="Hôpital lié"
          [dis]="true"
        ></app-input>
      </div>
      <!-- <div style="display: flex; width: 41%">
        <mat-icon>person</mat-icon>
        <div class="title_box" id="bill_to">
          <div id="title">genre</div>
          <div id="content">
            <select #gender>
              <option value="{{ user?.gender }}" style="font-weight: 600">
                {{ user?.gender }}
              </option>
              <option value="homme">homme</option>
              <option value="femme">femme</option>
            </select>
          </div>
        </div>
      </div> -->
    </div>
    <div style="display: flex; gap: 15em">
      <div style="display: flex; width: 30%">
        <mat-icon> work</mat-icon>
        <app-input
          value="{{ user?.jobposition }}"
          type="text"
          name="Poste"
          text="Poste"
          placeholder="Poste"
          [isRequired]="false"
        ></app-input>
      </div>
      <div style="display: flex; width: 30%">
        <mat-icon> person</mat-icon>
        <app-input
          value="{{ user?.sp }}"
          type="text"
          name="Spécialités"
          text="Spécialités"
          placeholder="Spécialités"
          [dis]="true"
          [isRequired]="false"
        ></app-input>
      </div>
    </div>
    <!-- <mat-icon style="color: #eb5d2b; cursor: pointer" (click)="info()"
      >info</mat-icon
    > -->

    <div style="display: flex; gap: 3em" *ngIf="user?.unv === null">
      <div style="display: flex; width: 41%">
        <mat-icon> </mat-icon>
        <div
          class="left-patient"
          style="flex-basis: 900px"
          title="Combien de temps faut-il entre le moment où vous recevez la notification et votre mise en route ? Vous recevrez une notification pour vous informer qu’il faut vous mettre en route vers le parking de l’hôpital."
        >
          <h3 class="title-p">Avance sur le Patient</h3>
          <div class="time-disp">
            <h3 style="cursor: pointer" (click)="incDec('asp', '-')">-</h3>
            <h3>{{ asp }} min</h3>
            <h3 style="cursor: pointer" (click)="incDec('asp', '+')">+</h3>
          </div>
        </div>
      </div>
      <div style="display: flex; width: 41%">
        <mat-icon> </mat-icon>
        <div
          class="left-patient"
          style="width: 140%"
          title="Afin de vous délivrer la notification au bon moment, nous avons besoin de connaître le temps de préparation entre le moment où vous arrivez sur le parking de l’hôpital et le moment où le patient arrive à l'hôpital."
        >
          <h3 class="title-p">Notification avant Départ</h3>
          <div class="time-disp">
            <h3 style="cursor: pointer" (click)="incDec('np', '-')">-</h3>
            <h3>{{ np }} min</h3>
            <h3 style="cursor: pointer" (click)="incDec('np', '+')">+</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="avance-patient" *ngIf="user?.unv === null">
      <div
        class="left-patient"
        title="Combien de temps faut-il entre le moment où vous recevez la notification et votre mise en route ? Vous recevrez une notification pour vous informer qu’il faut vous mettre en route vers le parking de l’hôpital."
      >
        <h3 class="title-p">Avance sur le Patient</h3>
        <div class="time-disp">
          <h3 style="cursor: pointer" (click)="incDec('asp', '-')">-</h3>
          <h3>{{ asp }} min</h3>
          <h3 style="cursor: pointer" (click)="incDec('asp', '+')">+</h3>
        </div>
      </div>
      <div
        class="right-patient"
        title="Afin de vous délivrer la notification au bon moment, nous avons besoin de connaître le temps de préparation entre le moment où vous arrivez sur le parking de l’hôpital et le moment où le patient arrive à l'hôpital."
      >
        <h3 class="title-p">Notification avant Départ</h3>
        <div class="time-disp">
          <h3 style="cursor: pointer" (click)="incDec('np', '-')">-</h3>
          <h3>{{ np }} min</h3>
          <h3 style="cursor: pointer" (click)="incDec('np', '+')">+</h3>
        </div>
      </div>
    </div> -->
    <div style="width: 100%; height: 100px"></div>
    <div [ngClass]="'buttons'">
      <!-- <app-button
        borderRadius="5px"
        class="white"
        text="Supprimer Mon compte"
        id="colorTextOrange"
        paddingLeft="30px"
        paddingRight="30px"
        paddingTop="10px"
        paddingBottom="10px"
        id="backgroundColor"
        icon="delete"
      >
      </app-button> -->
      <text-button
        text="Supprimer mon compte"
        icon="delete"
        [outlined]="true"
      ></text-button>
      <text-button
        (click)="Enregistrer()"
        text="Enregistrer"
        icon="save"
      ></text-button>
      <!-- <app-button
        (click)="Enregistrer()"
        borderRadius="5px"
        class="orange"
        text="Enregistrer"
        paddingLeft="40px"
        paddingRight="40px"
        paddingTop="10px"
        paddingBottom="10px"
        icon="save"
      ></app-button> -->
    </div>
    <!---->
  </div>
</div>
