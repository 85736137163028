<div class="create-account-container">
  <div class="container-create-account-left">
    <app-side-image></app-side-image>
  </div>
  <div class="container-create-account-right">
    <div class="return" (click)="landing()">
      <div
        class="d-flex flex-column justify-content-center align-items-center p-2"
      >
        <!--  (click)="logout()" -->
        <a routerLinkActive="button-active" class="navbar-icon">
          <mat-icon class="text-dark m-0">keyboard_backspace</mat-icon>
        </a>
      </div>
    </div>
    <h1>Créer Un Compte</h1>
    <div>
      <div class="inputes" *ngFor="let e of list">
        <mat-icon class="icon"> {{ e.icon }} </mat-icon>
        <app-input
          type="{{ e.type }}"
          name="{{ e.name }}"
          text="{{ e.text }}"
          placeholder="{{ e.placeholder }}"
        ></app-input>
      </div>
      <div class="inputes">
        <mat-icon class="icon"> https</mat-icon>
        <mat-form-field class="example-full-width w-100">
          <input
            matInput
            [(ngModel)]="password"
            [type]="passwordVisible ? 'text' : 'password'"
            placeholder="Mot de passe"
          />
          <mat-icon matSuffix (click)="togglePasswordVisibility()">{{
            passwordVisible ? "visibility_off" : "visibility"
          }}</mat-icon>
        </mat-form-field>
      </div>
      <div class="submit">
        <div class="errors">
          <li *ngFor="let error of errors" style="color: red">{{ error }}</li>
        </div>
        <button class="custom-btn" (click)="signup()">
          Demander un compte
        </button>

        <p style="cursor: pointer" (click)="login()">
          <span>Avez vous déjà un compte? </span>s'identifier
        </p>
      </div>
    </div>
  </div>
</div>
