<div class="container_image_doctor">
  <div class="container_top_line">
    <div>
      <img src="assets/cerveauCoeur.png" class="icon_top_line" />
      <h1><strong>Un temps d'avenir</strong></h1>
    </div>
  </div>
  <div class="container_bottom_line">
    <div>
      Fait avec
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
      >
        <g
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        >
          <path d="M7 14a3 3 0 1 0 1 5.83"></path>
          <path
            d="M4.264 15.605a4 4 0 0 1-.874-6.636m.03-.081A2.5 2.5 0 0 1 7 5.5m.238.065A2.5 2.5 0 1 1 12 4.5V20m-4 0a2 2 0 1 0 4 0m0-13a3 3 0 0 0 3 3m2 4a3 3 0 1 1-1 5.83"
          ></path>
          <path
            d="M19.736 15.605a4 4 0 0 0 .874-6.636m-.03-.081A2.5 2.5 0 0 0 17 5.5m-5-1a2.5 2.5 0 1 1 4.762 1.065M16 20a2 2 0 1 1-4 0"
          ></path>
        </g>
      </svg>
      Par iAVC
    </div>
  </div>
</div>
