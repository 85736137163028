<div class="choice" *ngIf="entryData.use === 'choice'">
  it seems like there is another event in the same slot do you want to ?
  <a (click)="choice('replace')">Replace</a>
  <a (click)="choice('replace')">Merge</a>
</div>

<div *ngIf="entryData.use === 'waiting'">
  <p [ngClass]="'paragraph'" style="padding-top: 50px">
    Vous demandez à ce que les données renseignées soient envoyées et traitées par l'équipe iAVC
    pour répondre à votre demande. Sans souscription au service ROMAIN par iAVC, ces données seront
    conservées pendant une année.
  </p>
  <p [ngClass]="'paragraph'">
    Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement. Pour
    exercer ces droits ou pour toute question, vous pouvez nous contacter par courriel à l'adresse
    equipe@romain.care
  </p>
  <p [ngClass]="'paragraph'" style="padding-bottom: 30px">
    Si vous estimez, après nous avoir contactés, que vos droits «Informatique et Libertés» ne sont
    pas respectés, vous pouvez adresser une réclamation à la CNIL.
  </p>
  <div [ngClass]="'button'">
    <button class="custom-btn" (click)="closeB()">Valider</button>
  </div>
</div>

<div *ngIf="entryData.use === 'contact'">
  <p [ngClass]="'contact'">
    {{ entryData.number }}
  </p>
</div>

<div *ngIf="entryData.use === 'Profil'" style="padding: 5%">
  <h1 [ngClass]="'titleH1'">Ajout Palge De Notification</h1>
  <div class="title_box" id="bill_to">
    <div id="title">M'aventir De</div>
    <input
      class="add-event"
      type="datetime-local"
      value="{{ n }}"
      #e
      (change)="onChangeInputAdd(e.value)"
      #start
    />
  </div>
  <div [ngClass]="'arrow'">
    <img [ngClass]="'arrowImg'" src="assets/arrow.png" />
  </div>

  <div class="title_box" id="bill_to">
    <div id="title">jusque</div>
    <input class="add-event" type="datetime-local" value="{{ addEnd }}" #end />
  </div>

  <div [ngClass]="'buttons'">
    <app-button
      borderRadius="5px"
      class="orange"
      text="Valider"
      paddingLeft="20px"
      paddingRight="40px"
      paddingTop="10px"
      paddingBottom="10px"
      (click)="addEvent(start.value, end.value)"
    >
    </app-button>
    <app-button
      borderRadius="5px"
      class="white"
      text="Annuler"
      paddingLeft="20px"
      paddingRight="40px"
      paddingTop="10px"
      paddingBottom="10px"
      id="backgroundColor"
      id="colorTextOrange"
      (click)="close()"
    ></app-button>
  </div>
</div>
<div *ngIf="entryData.use === 'updateEvent'" style="padding: 5%">
  <h1 [ngClass]="'titleH1'">Ajout Palge De Notification</h1>
  <div class="title_box" id="bill_to">
    <div id="title">M'aventir De</div>
    <input
      class="add-event"
      type="datetime-local"
      value="{{ s }}"
      #e
      (change)="onChangeInput(e.value)"
      #start
    />
  </div>
  <div [ngClass]="'arrow'">
    <img [ngClass]="'arrowImg'" src="assets/arrow.png" />
  </div>

  <div class="title_box" id="bill_to">
    <div id="title">jusque</div>
    <input class="add-event" type="datetime-local" value="{{ m }}" #end />
  </div>

  <div [ngClass]="'buttons'">
    <app-button
      borderRadius="5px"
      class="orange"
      text="Confirmer"
      paddingLeft="20px"
      paddingRight="40px"
      paddingTop="10px"
      paddingBottom="10px"
      (click)="updateEvent('PUT', e.value, end.value)"
    >
    </app-button>
    <app-button
      borderRadius="5px"
      class="white"
      text="Annuler"
      paddingLeft="20px"
      paddingRight="40px"
      paddingTop="10px"
      paddingBottom="10px"
      id="backgroundColor"
      id="colorTextOrange"
      (click)="close()"
    ></app-button>
  </div>
</div>
<div *ngIf="entryData.use === 'contactEye'" style="padding: 5%">
  <h1>{{ entryData.user.firstName }} {{ entryData.user.lastName }}</h1>
  <div style="margin-bottom: 9%; color: black">
    <div
      style="display: flex; gap: 5em; align-items: center; font-weight: bold; margin-bottom: 10px"
    >
      <div
        style="color: #eb5d2b; display: flex; gap: 1em; align-items: center; cursor: pointer"
        (click)="contactPhone(entryData.user.phone)"
      >
        <mat-icon> phone </mat-icon>Appeler
      </div>
      <div
        (click)="sendMail(entryData.user)"
        style="color: #eb5d2b; display: flex; gap: 1em; cursor: pointer; align-items: center"
      >
        <mat-icon> local_post_office </mat-icon>Contacter
      </div>
    </div>
    <div class="friend-container">
      <div class="left-side">
        <h4 style="text-align: center; color: #eb5d2b">Ses Informations</h4>
        <div [ngClass]="'information'">
          <div>
            <div style="display: flex; gap: 1em; margin-top: 22px">
              <mat-icon style="color: #b7b7b7">email</mat-icon>
              <div>{{ entryData.user.email }}</div>
            </div>
          </div>
          <div>
            <div style="display: flex; gap: 1em; margin-top: 22px">
              <mat-icon style="color: #b7b7b7">phone</mat-icon>
              <div>{{ entryData.user.phone }}</div>
            </div>
          </div>
          <div>
            <div style="display: flex; gap: 1em; margin-top: 22px">
              <mat-icon style="color: #b7b7b7">sensor_occupied</mat-icon>
              <div>{{ entryData.user.RPPS }}</div>
            </div>
          </div>
          <div>
            <div style="display: flex; gap: 1em; margin-top: 22px">
              <mat-icon style="color: #b7b7b7">room</mat-icon>
              <div>
                {{ value }}
              </div>
            </div>
          </div>
          <div>
            <div style="display: flex; gap: 1em; margin-top: 22px">
              <mat-icon style="color: #b7b7b7">work</mat-icon>
              <div>{{ entryData.user.jobposition }}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: grid; justify-items: center">
        <h4 style="color: #eb5d2b">Son Planning</h4>
        <div class="callender-profile">
          <full-calendar [options]="calendarOptions"></full-calendar>
        </div>
        <div *ngIf="dayEvents?.length > 0" class="day-events">
          <div class="day">
            <h2>{{ dayEvents[0]?.day?.slice(0, 2) }}</h2>
          </div>
          <div class="eventsList">
            <div class="day-event" *ngFor="let e of dayEvents">
              <h2>de {{ e?.trimedstart }} à {{ e?.trimedend }}</h2>
            </div>
          </div>
        </div>
        <div *ngIf="dayEvents?.length === 0" class="day-events">
          <div class="day">
            <h2>{{ tdy.slice(0, 2) }}</h2>
          </div>
          <div>
            <h2>Pas d'événements</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; place-content: center">
    <text-button [outlined]="true" text="Fermer" (click)="close()"></text-button>
  </div>
</div>

<div *ngIf="entryData.use === 'contactEye2'" style="padding: 5%">
  <h1>{{ entryData.user.firstName }} {{ entryData.user.lastName }}</h1>
  <div style="margin-bottom: 9%; color: black">
    <div
      style="display: flex; gap: 5em; align-items: center; font-weight: bold; margin-bottom: 10px"
    >
      <div
        style="color: #eb5d2b; display: flex; gap: 1em; align-items: center; cursor: pointer"
        (click)="contactPhone(entryData.user.phone)"
      >
        <mat-icon> phone </mat-icon>Appeler
      </div>
      <div
        (click)="sendMail(entryData.user)"
        style="color: #eb5d2b; display: flex; gap: 1em; cursor: pointer; align-items: center"
      >
        <mat-icon> local_post_office </mat-icon>Contacter
      </div>
    </div>
    <div class="friend-container2">
      <div class="left-side">
        <h4 style="text-align: center; color: #eb5d2b">Ses Informations</h4>
        <div [ngClass]="'information'">
          <div>
            <div style="display: flex; gap: 1em; margin-top: 22px">
              <mat-icon style="color: #b7b7b7">email</mat-icon>
              <div>{{ entryData.user.email }}</div>
            </div>
          </div>
          <div>
            <div style="display: flex; gap: 1em; margin-top: 22px">
              <mat-icon style="color: #b7b7b7">phone</mat-icon>
              <div>{{ entryData.user.phone }}</div>
            </div>
          </div>
          <div>
            <div style="display: flex; gap: 1em; margin-top: 22px">
              <mat-icon style="color: #b7b7b7">sensor_occupied</mat-icon>
              <div>{{ entryData.user.RPPS }}</div>
            </div>
          </div>
          <div>
            <div style="display: flex; gap: 1em; margin-top: 22px">
              <mat-icon style="color: #b7b7b7">room</mat-icon>
              <div>
                {{ value }}
              </div>
            </div>
          </div>
          <div>
            <div style="display: flex; gap: 1em; margin-top: 22px">
              <mat-icon style="color: #b7b7b7">work</mat-icon>
              <div>{{ entryData.user.jobposition }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; place-content: center">
    <text-button [outlined]="true" text="Fermer" (click)="close()"></text-button>
  </div>
</div>
