import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, concatMap, delay, Observable, of, retryWhen, throwError } from 'rxjs';
import { keyframes } from '@angular/animations';
import { Router } from '@angular/router';
import { MessageService } from '../services/message.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private messageService: MessageService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((err) =>
        err.pipe(
          concatMap((error: any, count: number) => {
            if (error.status == 401) {
              localStorage.removeItem('token');
              this.messageService.showMessage('La session a expiré');
              this.router.navigate(['/']);
              return of(error);
            }
            if (count < 3 && error.status == 504) {
              this.messageService.showMessage('Impossible de se connecter au serveur !');
              return of(error);
            }
            return throwError(error.error);
          }, delay(500)),
        ),
      ),
    );
  }
}
