<div class="container">
  <div class="conflict-container-title">
    <h1>Information</h1>
    <p>Vous voulez supprimer ce planning ou le modifier?</p>
  </div>

  <div class="form-actions-buttons-container">
    <text-button
      ngClass="small"
      text="Modifier"
      [outlined]="false"
      (click)="modifier()"
    ></text-button>

    <text-button
      ngClass="small"
      text="Supprimer"
      [outlined]="false"
      (click)="supprimer()"
    ></text-button>

    <text-button
      text="Annuler"
      ngClass="small"
      [outlined]="true"
      (click)="Cancel()"
    ></text-button>
  </div>
</div>
