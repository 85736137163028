<div class="login-container">
  <div class="left">
    <app-side-image></app-side-image>
  </div>
  <div class="container-login-right">
    <div class="return" (click)="landing()">
      <div
        class="d-flex flex-column justify-content-center align-items-center p-2"
      >
        <!--  (click)="logout()" -->
        <a routerLinkActive="button-active" class="navbar-icon">
          <mat-icon class="text-dark m-0">keyboard_backspace</mat-icon>
        </a>
      </div>
    </div>
    <app-logo class="logo"></app-logo>
    <div class="login-form">
      <div class="inputes">
        <mat-icon class="icon"> local_post_office</mat-icon>
        <app-input
          type="email"
          name="email"
          text="eMail"
          placeholder="eMail"
        ></app-input>
        <mat-icon class="icon">https</mat-icon>
        <mat-form-field class="example-full-width w-100">
          <input
            matInput
            [(ngModel)]="password"
            [type]="passwordVisible ? 'text' : 'password'"
            placeholder="Password"
          />
          <mat-icon matSuffix (click)="togglePasswordVisibility()">{{
            passwordVisible ? "visibility_off" : "visibility"
          }}</mat-icon>
        </mat-form-field>
      </div>

      <div class="submit">
        <div class="errors" style="margin-bottom: 15px">
          <span style="color: red; margin-bottom: 15px">{{ error }}</span>
        </div>
        <app-button
          borderRadius="8px"
          (click)="login()"
          text="se connecter"
          class="orange"
          paddingLeft="60px"
          paddingRight="60px"
          paddingTop="15px"
          paddingBottom="15px"
        ></app-button>
        <p style="cursor: pointer" (click)="forget()">mot de passe oublié?</p>
      </div>
    </div>
  </div>
</div>
