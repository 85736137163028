<div class="title-container">
  <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<div mat-dialog-content class="description-container">
  <div class="form-dropdown-picker-option">
    <div
      class="form-pick-option"
      *ngIf="
        data.selectedOption?.value === 'avc' ||
        data.selectedOption?.value === 'thrombolyse' ||
        data.selectedOption?.value === 'avis_thrombectomie'
      "
    >
      <label>
        <input
          type="radio"
          name="toggle"
          value="true"
          [checked]="
            data.selectedOption?.symptome?.value?.length > 10 ||
            data.selectedOption?.symptome?.value === true ||
            data.selectedOption?.symptome?.value === 'true'
          "
          (change)="onRadioChange(true)"
        />
        Oui
      </label>
      <label *ngIf="data.selectedOption?.value !== 'avc'">
        <input
          type="radio"
          name="toggle"
          value="non-renseigne"
          [checked]="
            data.selectedOption?.symptome === undefined ||
            data.selectedOption?.symptome?.value === null
          "
          (change)="onRadioChange(null)"
        />
        Non Renseigné
      </label>
      <label>
        <input
          type="radio"
          name="toggle"
          value="false"
          [checked]="
            data.selectedOption?.symptome?.value === false ||
            data.selectedOption?.symptome?.value === 'false'
          "
          (change)="onRadioChange(false)"
        />
        Non
      </label>
    </div>

    <div class="form-dropdown-time-container" *ngIf="isDate">
      <mat-icon class="input-icon" matPrefix> schedule </mat-icon>
      <input
        type="datetime-local"
        (change)="onDateTimeChange($event)"
        [value]="convertDateTime(data.selectedOption?.symptome?.value)"
      />
    </div>
  </div>
</div>
<div mat-dialog-actions class="buttons-container">
  <text-button
    text="Ajouter"
    [outlined]="false"
    (click)="onClickEvent()"
  ></text-button>

  <text-button
    text="Annuler"
    [outlined]="true"
    (click)="onCancelClick()"
  ></text-button>
</div>
