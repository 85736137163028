import { PatientUpdateAssocierBaliseComponent } from './../../shared/patient-update-associer-balise/patient-update-associer-balise.component';
import { PatientErrorDialogComponent } from './../../shared/patient-error-dialog/patient-error-dialog.component';
import { UserService } from './../../services/user.service';
import { BaliseService } from './../../services/balise.service';
import { ConfirmationDialogComponent } from './../../shared/confirmation-dialog/confirmation-dialog.component';
import { PatientFormUpdateDeleteDialogComponent } from './../../shared/patient-form-update-delete-dialog/patient-form-update-delete-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from './../../services/patient.service';
import { ExportDialogComponent } from './../../shared/export-dialog/export-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { formDropDownoptions } from '../../../constants/form-dropdown-options';
import { Router } from '@angular/router';
import { SymptomeChangeDialogComponent } from 'src/app/shared/symptome-change-dialog/symptome-change-dialog.component';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss'],
})
export class PatientDetailsComponent {
  patient: any;
  loading: boolean = true;
  symptoms: any[] = [];
  formDropDownoptions = formDropDownoptions;
  selectedOption: any = null;
  showDateTimePicker = false;
  nris: any[] = [];
  balises: any[] = [];
  user: any = null;
  selectedNri: any = null;
  firstName: string = '';
  lastName: string = '';
  birthday: string = '';
  opposition: boolean = false;
  patientId: string = '';
  baliseId: any = null;
  maxDate: Date = new Date();

  constructor(
    public dialog: MatDialog,
    private patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private baliseService: BaliseService,
    private userService: UserService,
  ) {}

  deleteDossierPatient() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Information',
        message: 'Voulez-vous supprimer ce dossier patient ?',
        actionButton: 'Supprimer',
        cancelButton: 'Annuler',
        action: 'delete',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'annuler') {
      } else if (result === 'delete') {
        this.patientService.deletePatient(this.patient.id).subscribe((data) => {
          this.router.navigate(['/home/patient']);
        });
      }
    });
  }

  onSymptomeClick(symptome: any) {
    const userHospital: string = this.user?.nri ? 'nri' : 'unv';

    if (symptome.hospital !== userHospital) {
      const dialogRef = this.dialog.open(PatientErrorDialogComponent, {
        width: '500px',
        data: {
          title: 'Erreur',
          description: 'Vous ne pouvez pas modifier cette option',
        },
      });
      return;
    }

    const dialogRef = this.dialog.open(PatientFormUpdateDeleteDialogComponent, {
      width: '500px',
      data: {
        title: symptome.title,
        description: symptome.value,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'update') {
        let find = this.formDropDownoptions.find((item) => item.name === symptome.title);

        if (find) {
          this.selectedOption = {
            name: find.name,
            value: find.value,
            symptome: symptome,
          };
        }

        const dialogRef = this.dialog.open(SymptomeChangeDialogComponent, {
          width: '500px',
          data: {
            title: 'Information',
            message: 'Voulez-vous supprimer ce dossier patient ?',
            selectedOption: this.selectedOption,
            isDate: this.checkIfDateTimePickShow(),
          },
          disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'annuler') {
            return;
          } else {
            if (result.isDate) {
              this.onDateTimeChange(result.value);
            } else {
              this.onRadioChange(result.value);
            }
          }
        });
      } else if (result === 'delete') {
        this.selectedOption = null;

        this.symptoms = this.symptoms.filter((item) => item.title !== symptome.title);
      }
    });
  }
  BaliseUsedId: any = 0;
  lastTransfer: any = null;
  ngOnInit() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.patientService.getPatient(id ? id : '0').subscribe((data) => {
      this.patientService.getNris().subscribe((nriData) => {
        this.userService.getCurrentUser().subscribe((userData) => {
          this.baliseService.getBalises().subscribe((baliseData) => {
            if (data.baliseHistory.length) {
              this.lastTransfer = data.baliseHistory[data.baliseHistory.length - 1];
            }
            if (data.balise) {
              this.BaliseUsedId = data.balise.id;
              this.baliseId = data?.balise?.id;
            }
            this.balises = baliseData;
            this.user = userData;
            this.firstName = data?.firstname;
            this.lastName = data?.lastname;
            this.birthday = data?.birthday;
            this.patientId = data?.id;
            this.opposition = data?.opposition === 'true';
            this.birthday = moment(this.birthday).format('YYYY-MM-DD');
            if (data?.nri) {
              this.selectedNri = data?.nri?.id;
            }

            const emptyNri = {
              id: null,
              name: 'Hôpital lié',
            };

            const myNri = {
              id: nriData?.id,
              name: nriData?.name,
            };

            this.nris = [emptyNri, myNri];

            if (nriData?.nris) {
              this.nris = [
                ...this.nris,
                ...nriData?.nris?.map((nri: any) => {
                  return {
                    id: nri.id,
                    name: nri.name,
                  };
                }),
              ];
            }

            this.patient = data;
            this.patient.opposition = this.patient?.opposition === 'true';
            this.patient.birthday = moment(this.patient?.birthday).format('DD/MM/YYYY');
            this.patient.created = moment(this.patient?.created).format('DD/MM/YYYY');

            // start symptoms

            if (this.patient?.options?.avc) {
              let dateRenseignement: any = moment(
                this.patient?.options?.avc?.dateRenseignement,
              ).format('DD/MM/YYYY à HH:mm');

              if (dateRenseignement === 'Invalid date') {
                dateRenseignement = this.patient?.options?.avc?.dateRenseignement;
              }

              this.symptoms.push({
                title: 'AVC',
                value: this.patient?.options?.avc?.value,
                dateRenseignement: dateRenseignement || '',
                user: this.patient?.options?.avc?.user || '',
                hospital: this.patient?.options?.avc?.hospital || '',
              });
            }

            if (this.patient?.options?.thrombolyse) {
              if (this.patient?.options?.thrombolyse.value === true) {
                let dateRenseignement: any = moment(
                  this.patient?.options?.thrombolyse_date?.dateRenseignement,
                ).format('DD/MM/YYYY à HH:mm');

                if (dateRenseignement === 'Invalid date') {
                  dateRenseignement = this.patient?.options?.thrombolyse_date?.dateRenseignement;
                }

                let value: any = moment(this.patient?.options?.thrombolyse_date?.value).format(
                  'DD/MM/YYYY à HH:mm',
                );

                if (value === 'Invalid date') {
                  value = this.patient?.options?.thrombolyse_date?.value;
                }

                this.symptoms.push({
                  title: 'Thrombolyse',
                  value: value || '',
                  dateRenseignement: dateRenseignement || '',
                  user: this.patient?.options?.thrombolyse_date?.user || '',
                  hospital: this.patient?.options?.thrombolyse_date?.hospital || '',
                });
              } else {
                let dateRenseignement: any = moment(
                  this.patient?.options?.thrombolyse?.dateRenseignement,
                ).format('DD/MM/YYYY à HH:mm');

                if (dateRenseignement === 'Invalid date') {
                  dateRenseignement = this.patient?.options?.thrombolyse?.dateRenseignement;
                }

                this.symptoms.push({
                  title: 'Thrombolyse',
                  value: this.patient?.options?.thrombolyse?.value,
                  dateRenseignement: dateRenseignement || '',
                  user: this.patient?.options?.thrombolyse?.user || '',
                  hospital: this.patient?.options?.thrombolyse?.hospital || '',
                });
              }
            }

            if (this.patient?.options?.avis_thrombectomie) {
              if (this.patient?.options?.avis_thrombectomie.value === true) {
                let dateRenseignement: any = moment(
                  this.patient?.options?.avis_thrombectomie_date?.dateRenseignement,
                ).format('DD/MM/YYYY à HH:mm');

                if (dateRenseignement === 'Invalid date') {
                  dateRenseignement =
                    this.patient?.options?.avis_thrombectomie_date?.dateRenseignement;
                }

                let value: any = moment(
                  this.patient?.options?.avis_thrombectomie_date?.value,
                ).format('DD/MM/YYYY à HH:mm');

                if (value === 'Invalid date') {
                  value = this.patient?.options?.avis_thrombectomie_date?.value;
                }

                this.symptoms.push({
                  title: 'Avis thrombectomie',
                  value: value || '',
                  dateRenseignement: dateRenseignement || '',
                  user: this.patient?.options?.avis_thrombectomie_date?.user || '',
                  hospital: this.patient?.options?.avis_thrombectomie_date?.hospital || '',
                });
              } else {
                let dateRenseignement: any = moment(
                  this.patient?.options?.avis_thrombectomie?.dateRenseignement,
                ).format('DD/MM/YYYY à HH:mm');

                if (dateRenseignement === 'Invalid date') {
                  dateRenseignement = this.patient?.options?.avis_thrombectomie?.dateRenseignement;
                }

                this.symptoms.push({
                  title: 'Avis thrombectomie',
                  value: this.patient?.options?.avis_thrombectomie?.value,
                  dateRenseignement: dateRenseignement || '',
                  user: this.patient?.options?.avis_thrombectomie?.user || '',
                  hospital: this.patient?.options?.avis_thrombectomie?.hospital || '',
                });
              }
            }

            if (this.patient?.options?.debut_symptome) {
              let dateRenseignement: any = moment(
                this.patient?.options?.debut_symptome?.dateRenseignement,
              ).format('DD/MM/YYYY à HH:mm');

              if (dateRenseignement === 'Invalid date') {
                dateRenseignement = this.patient?.options?.debut_symptome?.dateRenseignement;
              }

              let value: any = moment(this.patient?.options?.debut_symptome?.value).format(
                'DD/MM/YYYY à HH:mm',
              );

              if (value === 'Invalid date') {
                value = this.patient?.options?.debut_symptome?.value;
              }

              this.symptoms.push({
                title: 'Début des symptômes',
                value: value || '',
                dateRenseignement: dateRenseignement || '',
                user: this.patient?.options?.debut_symptome?.user || '',
                hospital: this.patient?.options?.debut_symptome?.hospital || '',
              });
            }

            if (this.patient?.options?.arrivee_urgence) {
              let dateRenseignement: any = moment(
                this.patient?.options?.arrivee_urgence?.dateRenseignement,
              ).format('DD/MM/YYYY à HH:mm');

              if (dateRenseignement === 'Invalid date') {
                dateRenseignement = this.patient?.options?.arrivee_urgence?.dateRenseignement;
              }

              let value: any = moment(this.patient?.options?.arrivee_urgence?.value).format(
                'DD/MM/YYYY à HH:mm',
              );

              if (value === 'Invalid date') {
                value = this.patient?.options?.arrivee_urgence?.value;
              }

              this.symptoms.push({
                title: 'Arrivée urgences',
                value: value || '',
                dateRenseignement: dateRenseignement || '',
                user: this.patient?.options?.arrivee_urgence?.user || '',
                hospital: this.patient?.options?.arrivee_urgence?.hospital || '',
              });
            }

            if (this.patient?.options?.prise_de_sang) {
              let dateRenseignement: any = moment(
                this.patient?.options?.prise_de_sang?.dateRenseignement,
              ).format('DD/MM/YYYY à HH:mm');

              if (dateRenseignement === 'Invalid date') {
                dateRenseignement = this.patient?.options?.prise_de_sang?.dateRenseignement;
              }

              let value: any = moment(this.patient?.options?.prise_de_sang?.value).format(
                'DD/MM/YYYY à HH:mm',
              );

              if (value === 'Invalid date') {
                value = this.patient?.options?.prise_de_sang?.value;
              }

              this.symptoms.push({
                title: 'Prise de sang',
                value: value || '',
                dateRenseignement: dateRenseignement || '',
                user: this.patient?.options?.prise_de_sang?.user || '',
                hospital: this.patient?.options?.prise_de_sang?.hospital || '',
              });
            }

            if (this.patient?.options?.dernier_plan_imagerie) {
              let dateRenseignement: any = moment(
                this.patient?.options?.dernier_plan_imagerie?.dateRenseignement,
              ).format('DD/MM/YYYY à HH:mm');

              if (dateRenseignement === 'Invalid date') {
                dateRenseignement = this.patient?.options?.dernier_plan_imagerie?.dateRenseignement;
              }

              let value: any = moment(this.patient?.options?.dernier_plan_imagerie?.value).format(
                'DD/MM/YYYY à HH:mm',
              );

              if (value === 'Invalid date') {
                value = this.patient?.options?.dernier_plan_imagerie?.value;
              }

              this.symptoms.push({
                title: "Dernier plan d'imagerie",
                value: value || '',
                dateRenseignement: dateRenseignement || '',
                user: this.patient?.options?.dernier_plan_imagerie?.user || '',
                hospital: this.patient?.options?.dernier_plan_imagerie?.hospital || '',
              });
            }

            if (this.patient?.options?.debut_de_transfert) {
              let dateRenseignement: any = moment(
                this.patient?.options?.debut_de_transfert?.dateRenseignement,
              ).format('DD/MM/YYYY à HH:mm');

              if (dateRenseignement === 'Invalid date') {
                dateRenseignement = this.patient?.options?.debut_de_transfert?.dateRenseignement;
              }

              let value: any = moment(this.patient?.options?.debut_de_transfert?.value).format(
                'DD/MM/YYYY à HH:mm',
              );

              if (value === 'Invalid date') {
                value = this.patient?.options?.debut_de_transfert?.value;
              }

              this.symptoms.push({
                title: 'Début de transfert',
                value: value || '',
                dateRenseignement: dateRenseignement || '',
                user:
                  this.patient?.options?.debut_de_transfert?.user || 'Renseigné automatiquement',
                hospital: this.patient?.options?.debut_de_transfert?.hospital || '',
                isAuto: true,
              });
            }

            if (this.patient?.options?.arrivee_unv_de_recours) {
              let dateRenseignement: any = moment(
                this.patient?.options?.arrivee_unv_de_recours?.dateRenseignement,
              ).format('DD/MM/YYYY à HH:mm');

              if (dateRenseignement === 'Invalid date') {
                dateRenseignement =
                  this.patient?.options?.arrivee_unv_de_recours?.dateRenseignement;
              }

              let value: any = moment(this.patient?.options?.arrivee_unv_de_recours?.value).format(
                'DD/MM/YYYY à HH:mm',
              );

              if (value === 'Invalid date') {
                value = this.patient?.options?.arrivee_unv_de_recours?.value;
              }

              this.symptoms.push({
                title: 'Arrivée UNV de recours',
                value: value || '',
                dateRenseignement: dateRenseignement || '',
                user:
                  this.patient?.options?.arrivee_unv_de_recours?.user ||
                  'Renseigné automatiquement',
                hospital: this.patient?.options?.arrivee_unv_de_recours?.hospital || '',
                isAuto: true,
              });
            }

            if (this.patient?.options?.debut_thrombectomie) {
              let dateRenseignement: any = moment(
                this.patient?.options?.debut_thrombectomie?.dateRenseignement,
              ).format('DD/MM/YYYY à HH:mm');

              if (dateRenseignement === 'Invalid date') {
                dateRenseignement = this.patient?.options?.debut_thrombectomie?.dateRenseignement;
              }

              let value: any = moment(this.patient?.options?.debut_thrombectomie?.value).format(
                'DD/MM/YYYY à HH:mm',
              );

              if (value === 'Invalid date') {
                value = this.patient?.options?.debut_thrombectomie?.value;
              }

              this.symptoms.push({
                title: 'Début thrombectomie',
                value: value || '',
                dateRenseignement: dateRenseignement || '',
                user: this.patient?.options?.debut_thrombectomie?.user || '',
                hospital: this.patient?.options?.debut_thrombectomie?.hospital || '',
              });
            }

            if (this.patient?.options?.fin_thrombectomie) {
              let dateRenseignement: any = moment(
                this.patient?.options?.fin_thrombectomie?.dateRenseignement,
              ).format('DD/MM/YYYY à HH:mm');

              if (dateRenseignement === 'Invalid date') {
                dateRenseignement = this.patient?.options?.fin_thrombectomie?.dateRenseignement;
              }

              let value: any = moment(this.patient?.options?.fin_thrombectomie?.value).format(
                'DD/MM/YYYY à HH:mm',
              );

              if (value === 'Invalid date') {
                value = this.patient?.options?.fin_thrombectomie?.value;
              }

              this.symptoms.push({
                title: 'Fin thrombectomie',
                value: value || '',
                dateRenseignement: dateRenseignement || '',
                user: this.patient?.options?.fin_thrombectomie?.user || '',
                hospital: this.patient?.options?.fin_thrombectomie?.hospital || '',
              });
            }

            // end symptoms

            const userHospital: string = this.user?.nri ? 'nri' : 'unv';

            for (let i = 0; i < this.symptoms.length; i++) {
              if (this.symptoms[i].hospital != userHospital) {
                // remove that value from this.formDropdownOptions
                this.formDropDownoptions = this.formDropDownoptions.filter((option: any) => {
                  return option.name != this.symptoms[i].title;
                });
              }
            }

            const booleanSymptoms: any = [];

            for (let i = 0; i < this.symptoms.length; i++) {
              const convertedDate = this.convertDateTime(this.symptoms[i].value);
              const timeIsoString = moment(convertedDate).toISOString();

              if (timeIsoString === null) {
                booleanSymptoms.push(this.symptoms[i]);
                this.symptoms.splice(i, 1);
                i = i - 1;
              }
            }

            this.symptoms.sort((a: any, b: any) => {
              const convertedDateA = this.convertDateTime(a.value);
              const convertedDateB = this.convertDateTime(b.value);

              const timeIsoStringA = moment(convertedDateA).toISOString();
              const timeIsoStringB = moment(convertedDateB).toISOString();

              return timeIsoStringA > timeIsoStringB ? 1 : -1;
            });

            this.symptoms = booleanSymptoms.concat(this.symptoms);
            this.loading = false;
          });
        });
      });
    });
  }
  annule() {
    this.router.navigateByUrl('/home/patient');
  }

  exportPatient() {
    const dialogRef = this.dialog.open(ExportDialogComponent, {
      width: '50%',
      height: '70%',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'print') {
        this.patientService.downloadDossier(this.patientId).subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            const printWindow: any = window.open(url);

            printWindow.onload = () => {
              printWindow.print();
            };
          },
          (err) => {
            console.log('err ', err);
          },
        );
      }

      if (result === 'sendEmail') {
        this.patientService.downloadDossier(this.patientId).subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = this.patientId + '.pdf'; // Replace with your desired file name and extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.open(`mailto:?subject=file&body=add`);
          },
          (err) => {
            console.log('err ', err);
          },
        );
      }

      if (result === 'download') {
        this.patientService.downloadDossier(this.patientId).subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('download', 'dossier.pdf');
            link.setAttribute('href', url);
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          (err) => {
            console.log('err ', err);
          },
        );
      }
    });
  }

  toggleOpposition() {
    this.opposition = !this.opposition;
  }

  onOptionChange(event: any) {
    this.selectedOption = event.value;

    // get the object from symptoms array
    const find = this.symptoms.find(
      (symptom) => symptom.title.toLowerCase() === this.selectedOption.name.toLowerCase(),
    );
    if (find) {
      this.selectedOption.symptome = find;
    } else {
      this.selectedOption.symptome = {
        dateRenseignement: null,
        hospital: null,
        title: null,
        user: null,
        value: null,
      };
    }

    const dialogRef = this.dialog.open(SymptomeChangeDialogComponent, {
      width: '500px',
      data: {
        title: 'Information',
        message: 'Voulez-vous supprimer ce dossier patient ?',
        selectedOption: this.selectedOption,
        isDate: this.checkIfDateTimePickShow(),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'annuler') {
        return;
      } else {
        if (result.isDate) {
          this.onDateTimeChange(result.value);
        } else {
          this.onRadioChange(result.value);
        }
      }
    });
  }

  onRadioChange(value: any) {
    if (value === undefined || value === null) {
      this.symptoms = this.symptoms.filter((symptom) => symptom.title !== this.selectedOption.name);
      return;
    }

    this.selectedOption.symptome.value = value;
    this.selectedOption.symptome.dateRenseignement = moment().format('DD/MM/YYYY à HH:mm');
    this.selectedOption.symptome.user = this.user.firstName + ' ' + this.user.lastName;
    this.selectedOption.symptome.hospital = this.user.nri ? 'nri' : 'unv';
    formDropDownoptions.forEach((option) => {
      if (option.name === this.selectedOption.name) {
        this.selectedOption.symptome.title = option.name;
      }
    });

    let find = this.symptoms.find(
      (symptom) => symptom.title.toLowerCase() === this.selectedOption.name.toLowerCase(),
    );

    if (find) {
      find = this.selectedOption.symptome;
    } else {
      this.symptoms.push(this.selectedOption.symptome);
    }
  }

  onDateTimeChange(event: any) {
    const selectedDate = event.target.value;

    if (moment(selectedDate).isAfter(moment())) {
      const dialogRef = this.dialog.open(PatientErrorDialogComponent, {
        width: '500px',
        data: {
          title: 'Erreur',
          description: 'Vous ne pouvez pas renseigner une date future',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'cancel') {
        }
      });

      return;
    }

    if (selectedDate === undefined || selectedDate === null) {
      this.symptoms = this.symptoms.filter((symptom) => symptom.title !== this.selectedOption.name);
      return;
    }

    this.selectedOption.symptome.value = this.convertDateTimeToFormat(selectedDate);
    this.selectedOption.symptome.dateRenseignement = moment().format('DD/MM/YYYY à HH:mm');
    this.selectedOption.symptome.user = this.user.firstName + ' ' + this.user.lastName;
    this.selectedOption.symptome.hospital = this.user.nri ? 'nri' : 'unv';
    formDropDownoptions.forEach((option) => {
      if (option.name === this.selectedOption.name) {
        this.selectedOption.symptome.title = option.name;
      }
    });

    let find = this.symptoms.find(
      (symptom) => symptom.title.toLowerCase() === this.selectedOption.name.toLowerCase(),
    );

    if (find) {
      find = this.selectedOption.symptome;
    } else {
      this.symptoms.push(this.selectedOption.symptome);
    }
  }

  convertDateTime(dateTime: string): string {
    // Parse the input date and time string using Moment.js
    const parsedDateTime = moment(dateTime, 'DD/MM/YYYY à HH:mm');

    // Format the date and time using Moment.js
    const formattedDateTime = parsedDateTime.format('YYYY-MM-DDTHH:mm');

    return formattedDateTime;
  }

  convertDateTimeToFormat(dateTime: string): string {
    // Parse the input date and time string using Moment.js
    const parsedDateTime = moment(dateTime, 'YYYY-MM-DDTHH:mm');

    // Format the date and time using Moment.js
    const formattedDateTime = parsedDateTime.format('DD/MM/YYYY à HH:mm');

    return formattedDateTime;
  }

  checkIfDateTimePickShow() {
    if (this.selectedOption?.value == null || this.selectedOption?.value == undefined) {
      return false;
    }

    if (this.selectedOption?.value == 'avc') {
      return false;
    }
    if (this.selectedOption?.value == 'thrombolyse') {
      if (this.selectedOption?.symptome?.value?.length > 10) {
        return true;
      }
      if (this.selectedOption?.symptome?.value == true) {
        return true;
      }
      return false;
      return false;
    }
    if (this.selectedOption?.value == 'avis_thrombectomie') {
      if (this.selectedOption?.symptome?.value?.length > 10) {
        return true;
      }
      if (this.selectedOption?.symptome?.value == true) {
        return true;
      }
      return false;
    }
    return true;
  }

  updatePatient() {
    let isError: any = null;

    this.symptoms.forEach((symptom) => {
      if (symptom.value === null || symptom.value === undefined) {
        isError = {
          title: 'Erreur ' + symptom.title,
          description: `La valeur du ${symptom.title} est vide`,
        };
      }
      if (symptom.title === 'Thrombolyse' && symptom.value === true) {
        isError = {
          title: 'Erreur ' + symptom.title,
          description: `Veiullez renseigner la date de ${symptom.title}`,
        };
      } else if (symptom.title === 'Avis thrombectomie' && symptom.value === true) {
        isError = {
          title: 'Erreur ' + symptom.title,
          description: `Veiullez renseigner la date de ${symptom.title}`,
        };
      }
    });

    if (isError) {
      const dialogRef = this.dialog.open(PatientErrorDialogComponent, {
        width: '500px',
        data: {
          title: isError.title,
          description: isError.description,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'cancel') {
        }
      });
      return;
    }

    this.loading = true;

    let options: any = {};
    this.symptoms.forEach((symptom) => {
      formDropDownoptions.forEach((option) => {
        if (option.name.toLowerCase() === symptom.title.toLowerCase()) {
		
		  if (symptom.value == true || symptom.value == false) {
		  
		  } else {
            const convertedDateTime = this.convertDateTime(symptom.value);
            const isoString = convertedDateTime;

            if (isoString != null) {
              symptom.value = isoString;
            }
		  }
          

          const convertedDateTimeRenseignement = this.convertDateTime(symptom.dateRenseignement);

          const isoStringRenseignement = convertedDateTimeRenseignement;

          if (isoStringRenseignement != null) {
            symptom.dateRenseignement = isoStringRenseignement;
          }

          if (
            option.value === 'thrombolyse' &&
            (symptom.value == true || symptom.value.length > 10)
          ) {
            options['thrombolyse_date'] = {
              dateRenseignement: symptom.dateRenseignement,
              hospital: symptom.hospital,
              user: symptom.user,
              value: symptom.value,
            };

            options['thrombolyse'] = {
              dateRenseignement: symptom.dateRenseignement,
              hospital: symptom.hospital,
              user: symptom.user,
              value: true,
            };
          } else if (
            option.value === 'avis_thrombectomie' &&
            (symptom.value === true || symptom.value.length > 10)
          ) {
            options['avis_thrombectomie_date'] = {
              dateRenseignement: symptom.dateRenseignement,
              hospital: symptom.hospital,
              user: symptom.user,
              value: symptom.value,
            };

            options['avis_thrombectomie'] = {
              dateRenseignement: symptom.dateRenseignement,
              hospital: symptom.hospital,
              user: symptom.user,
              value: true,
            };
          } else {
            options[option.value] = {
              dateRenseignement: symptom.dateRenseignement,
              hospital: symptom.hospital,
              user: symptom.user,
              value: symptom.value,
            };
          }
        }
      });
    });

    const birthdayIsoString = moment(this.birthday).toISOString();

    const encodedOptions = JSON.stringify(options);

    const body = {
      firstname: this.firstName.toString(),
      lastname: this.lastName.toString(),
      birthday: birthdayIsoString,
      opposition: this.opposition.toString(),
      options: encodedOptions,
    };

    this.patientService.updatePatient(this.patientId, body).subscribe(
      (response) => {
        this.loading = false;
        this.router.navigate(['/home/patient']);
      },
      (error) => {
        this.loading = false;
      },
    );
  }

  associerUneBalise() {
    const emptyBalise = {
      id: -1,
      name: 'Sélectionner une balise',
    };

    const balisesToDisplay = [emptyBalise, ...this.balises];
    const dialogRef = this.dialog.open(PatientUpdateAssocierBaliseComponent, {
      width: '500px',
      data: {
        balises: balisesToDisplay,
      },
    });

    dialogRef.afterClosed().subscribe((selectedBalise) => {
      if (selectedBalise === 'cancel') {
        return;
      }

      if (selectedBalise.selectedBalise === -1 || !selectedBalise.selectedBalise) {
        const dialogRef = this.dialog.open(PatientErrorDialogComponent, {
          width: '500px',
          data: {
            title: 'Erreur',
            description: 'Veuillez sélectionner une balise',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'cancel') {
          }
        });

        return;
      }

      this.associerToBalise(selectedBalise.selectedBalise, selectedBalise.description);
    });
  }

  onChangeSelectedNRI(event: any) {
    if (event.value === this.selectedNri) {
      return;
    }
    this.selectedNri = event.value;

    let message = 'Voulez-vous vraiment associer ce patient à ce NRI ?';

    if (event.value === null) {
      message = 'Voulez-vous vraiment dissocier ce patient de ce NRI ?';
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Information',
        message: message,
        actionButton: 'Confirmer',
        cancelButton: 'Annuler',
        action: 'update',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'annuler') {
      } else if (result === 'update') {
        if (this.selectedNri === null) {
          this.associerToNri();
        } else {
          this.associerToNri();
        }
      }
    });
  }

  associerToNri() {
    this.patientService.assignNri(this.patientId, this.selectedNri).subscribe((response) => {
      this.loading = false;
      this.router.navigate(['/home/patient']);
    });
  }

  associerToBalise(selectedBalise: any, description: any) {
    this.patientService
      .assignBalise(this.patientId, selectedBalise, description)
      .subscribe((response) => {
        this.loading = false;
        this.router.navigate(['/home/patient']);
      });
  }

  dissocierFromBalise() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Information',
        message: 'Voulez-vous vraiment dissocier ce patient de cette balise ?',
        actionButton: 'Confirmer',
        cancelButton: 'Annuler',
        action: 'update',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'annuler') {
      } else if (result === 'update') {
        this.patientService.dissociateBalise(this.baliseId).subscribe((response) => {
          this.loading = false;
          this.router.navigate(['/home/patient']);
        });
      }
    });
  }

  showCurrentTransfert(baliseId: number) {
    this.router.navigate(['/home/balises'], {
      queryParams: {
        id: this.BaliseUsedId ?? -1,
      },
    });
  }
}
