<div class="title-container">
  <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<div mat-dialog-content class="description-container">
  <p>{{ data.message }}</p>
</div>
<div mat-dialog-actions class="buttons-container">
  <!-- <button mat-button color="primary" (click)="onClickEvent()">
    {{ data.actionButton }}
  </button>
  <button mat-button color="warn" (click)="onCancelClick()">
    {{ data.cancelButton }}
  </button> -->
  <text-button
    text="{{ data.actionButton }}"
    [outlined]="false"
    (click)="onClickEvent()"
  ></text-button>

  <text-button
    text="{{ data.cancelButton }}"
    [outlined]="true"
    (click)="onCancelClick()"
  ></text-button>
</div>
