<!-- <div *ngIf="loading === true" class="loading-container">
  <mat-spinner class="loading-spinner"></mat-spinner>
</div> -->

<!-- <div *ngIf="loading === false"> -->
<div>
  <h1 style="margin-left: 0.5rem">Listes Des Patients</h1>
  <div style="width: 250px">
    <text-button
      [outlined]="false"
      icon="add"
      text="Ajouter un patient"
      [routerLink]="'/home/patient/add'"
    ></text-button>
  </div>

  <div class="seach-grid">
    <div class="search-bar-white-container">
      <mat-icon class="search-icon" matPrefix>search</mat-icon>
      <input
        class="search-input"
        matInput
        placeholder="Chercher"
        [(ngModel)]="search"
        (keyup)="searchPatient()"
      />
    </div>
    <!-- <div class="filter-white-container">
      <mat-icon class="filter-icon" matPrefix>tune</mat-icon>
      <span class="filter-text">Filtrer</span>
    </div> -->
  </div>

  <div class="patients-scroll-list">
    <div>
      <div
        class="patient-card-white-container"
        (click)="redirectPatient(patient.id, patient)"
        *ngFor="let patient of searchedPatients"
      >
        <div class="patient-informations">
          <div class="patient-informations-name-birthday">
            <span class="patient-name">
              {{ patient?.firstname }} {{ patient?.lastname }}
            </span>
            <span class="patient-birthday"
              >Né(e) le {{ patient?.birthday }}</span
            >
          </div>
          <div class="patient-birthday">Créé le {{ patient?.created }}</div>
        </div>

        <div class="patient-card-white-container-icon">
          <mat-icon class="patient-card-white-container-icon-eye"
            >visibility</mat-icon
          >
        </div>
      </div>
    </div>
  </div>
</div>
