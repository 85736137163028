import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from './../../services/notification.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  constructor(
    public notificationService: NotificationService,
    public auth: AuthService,
    public us: UserService,
  ) {}
  user: any;
  ngOnInit() {
    this.notificationService.startListeningOnNotifications();
    this.us.getCurrentUser().subscribe((res) => {
      this.user = res;
    });
  }
  disconnect() {
    this.auth.logoutecps().subscribe((data) => {
      localStorage.removeItem('token');
      window.location.reload();
    });
  }
}
