import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-event-click-popup',
  templateUrl: './event-click-popup.component.html',
  styleUrls: ['./event-click-popup.component.scss'],
})
export class EventClickPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<EventClickPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  modifier() {
    this.dialogRef.close('modifier');
  }

  supprimer() {
    this.dialogRef.close('supprimer');
  }

  Cancel() {
    this.dialogRef.close('cancel');
  }
}
