<div class="title-container">
  <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<div mat-dialog-content class="description-container">
  <p>
    {{
      data.description == "false" || data.description == false
        ? "Non Renseigné"
        : data.description == "true" || data.description == true
        ? "Renseigné"
        : data.description
    }}
  </p>
</div>
<div mat-dialog-actions class="buttons-container">
  <!-- <button mat-button color="primary" (click)="onUpdateClick()">
    Modifier
  </button>
  <button mat-button color="warn" (click)="onDeleteClick()">
    Supprimer
  </button> -->
  <text-button
    text="Modifier"
    [outlined]="false"
    (click)="onUpdateClick()"
  ></text-button>

  <text-button
    text="Supprimer"
    [outlined]="true"
    (click)="onDeleteClick()"
  ></text-button>
</div>
