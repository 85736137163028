<div class="container">
  <div class="conflict-container-title">
    <h1>Conflit</h1>

    <p>Votre nouvelle plage est en conflit avec une plage déjà renseignée</p>
  </div>

  <div class="form-actions-buttons-container">
    <text-button
      text="Fusionner"
      [outlined]="false"
      (click)="Merge()"
    ></text-button>

    <text-button
      text="Remplacer"
      [outlined]="false"
      (click)="Replace()"
    ></text-button>

    <text-button
      text="Annuler"
      [outlined]="true"
      (click)="Cancel()"
    ></text-button>
  </div>
</div>
