<div class="container">
  <div class="conflict-container-title">
    <h1>Information</h1>
    <li>Notification Avant Départ</li>
    <p>
      Combien de temps faut-il entre le moment où vous recevez la notification
      et votre mise en route ? Vous recevrez une notification pour vous informer
      qu’il faut vous mettre en route vers le parking de l’hôpital.
    </p>
    <li>Avance sur le Patient</li>
    <p>
      Afin de vous délivrer la notification au bon moment, nous avons besoin de
      connaître le temps de préparation entre le moment où vous arrivez sur le
      parking de l’hôpital et le moment où le patient arrive à l\'hôpital.
    </p>
  </div>

  <div class="form-actions-buttons-container">
    <text-button text="Valider" [outlined]="false"></text-button>
  </div>
</div>
