<h1>Contacts</h1>
<div class="contacts-container">
  <h3>Autres Utilisateurs du Centre</h3>
  <div style="display: grid; grid-gap: 1em">
    <div
      [ngClass]="'other-contact-tile'"
      *ngFor="let e of collegsList"
      (click)="Eye(e)"
      style="cursor: pointer"
    >
      <div class="friend-connect">
        <mat-icon *ngIf="!e.connections" style="color: #eb2b2b"> fiber_manual_record </mat-icon>
        <mat-icon *ngIf="e.connections" style="color: green"> fiber_manual_record </mat-icon>
        <h2>{{ e.firstName + ' ' + e.lastName }}</h2>
      </div>
      <mat-icon style="color: #848484; cursor: pointer; justify-self: center">
        visibility
      </mat-icon>
    </div>
  </div>
  <h3 style="margin-top: 25px">Contacter un Administrateur</h3>
  <div style="display: grid; grid-gap: 1em; align-items: center">
    <div class="contact-tile" *ngFor="let e of adminsList">
      <h2 class="my-auto mx-3">{{ e.firstName + ' ' + e.lastName }}</h2>
      <div class="contact-way" (click)="contact('phone', e.phone)">
        <p>Appeler</p>
        <mat-icon> phone </mat-icon>
      </div>
      <div class="contact-way" (click)="sendMail(e)">
        <p>Contacter</p>
        <mat-icon> local_post_office </mat-icon>
      </div>
    </div>
  </div>
</div>
