import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications: any[] = [];
  constructor(
    private socket: Socket,
    private http: HttpClient,
  ) {}

  public startListeningOnNotifications() {
    this.getNotifications().subscribe((data) => {
      data.forEach((element: any) => {
        this.notifications.unshift(element);
      });
    });
    this.onNotification().forEach((data: any) => {
      const formattedNotification = {
        message: data?.notification?.title ?? 'Unavailable message',
        read: false,
        date: new Date().toISOString(),
        id: data?.notification?.id ?? '',
      };
      this.notifications.unshift(formattedNotification);
    });
  }

  getNotifications() {
    return this.http.get<any>('https://romain.care/api/user/notifications');
  }

  markOneNotificationAsRead(id: any) {
    return this.http.post<any>('https://romain.care/api/user/notifications/unread', {
      ids: JSON.stringify([id]),
    });
  }

  public onNotification() {
    return this.socket.fromEvent<any[]>('service-notification');
  }

  public clearNotifications() {
    const ids = this.notifications.map((notification) => notification.id);
    return this.http.post<any>('https://romain.care/api/user/notifications/unread', {
      ids: JSON.stringify(ids),
    });
  }
  public howManyUnread() {
    const howManyUnread = this.notifications.filter((notification: any) => !notification?.read);

    return howManyUnread.length;
  }
}
