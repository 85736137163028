<!-- <div *ngIf="loading === true" class="loading-container">
  <mat-spinner class="loading-spinner"></mat-spinner>
</div> -->

<!-- <div *ngIf="loading === false"> -->
<div>
  <div class="header">
    <div class="return" (click)="annule()">
      <!--  (click)="logout()" -->
      <a routerLinkActive="button-active">
        <mat-icon class="text-dark m-0" style="align-self: center; margin: 0px"
          >keyboard_backspace</mat-icon
        >
      </a>
    </div>
    <h1>Dossier Patient</h1>

    <div class="header-buttons">
      <text-button
        text="Exporter"
        (click)="exportPatient()"
        [outlined]="false"
      ></text-button>

      <text-button
        text="Supprimer"
        [outlined]="false"
        (click)="deleteDossierPatient()"
      ></text-button>
    </div>
  </div>
  <p class="lastTransfer" *ngIf="lastTransfer">
    Patient Transféré de <span>{{ lastTransfer.unv.name }}</span> vers
    <span>{{ lastTransfer.nri.name }}</span>
  </p>

  <div class="name-inputs-container">
    <div class="input-container">
      <mat-icon class="input-icon" matPrefix>person</mat-icon>
      <div class="input-custom">
        <mat-form-field style="width: 100%">
          <mat-label>Nom</mat-label>
          <input
            type="text"
            matInput
            placeholder="Nom"
            [(ngModel)]="lastName"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="input-container">
      <mat-icon class="input-icon" matPrefix>person</mat-icon>
      <div class="input-custom">
        <mat-form-field style="width: 100%">
          <mat-label>Prénom</mat-label>
          <input
            type="text"
            matInput
            placeholder="Prénom"
            [(ngModel)]="firstName"
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- <div class="birthday-input-container">
    <div class="input-container">
      <mat-icon class="input-icon" matPrefix> event </mat-icon>
      <div class="input-custom">
        <label class="input-label">Date de naissance</label>
        <mat-form-field
          class="birthday-input-container"
          appearance="fill"
          (click)="picker.open()"
        >
          <mat-label> Date de naissance </mat-label>
          <input
            [(ngModel)]="birthday"
            matInput
            readonly="true"
            [matDatepicker]="picker"
            [max]="maxDate"
          />
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div> -->

  <div class="birthday-input-container">
    <div class="input-container">
      <mat-icon class="input-icon" matPrefix> event </mat-icon>
      <div class="input-custom">
        <label class="input-label">Date de naissance</label>

        <input
          type="date"
          class="input-field"
          placeholder="Date de naissance"
          [(ngModel)]="birthday"
        />
      </div>
    </div>
  </div>

  <div class="opposition-container">
    <div class="opposition-checkbox" (click)="toggleOpposition()">
      <div *ngIf="opposition === true" class="opposition-circle"></div>
    </div>
    <span> Le patient s'oppose à la réutilisation de ses données </span>
  </div>

  <div class="form-drop-down-col">
    <div class="form-dropdown-container">
      <div class="form-dropdown">
        <mat-icon class="input-icon" matPrefix> note_alt </mat-icon>
        <mat-select
          class="form-dropdown-select"
          placeholder="Sélectionner une option"
          appearance="fill"
          (selectionChange)="onOptionChange($event)"
        >
          <mat-option
            *ngFor="let option of formDropDownoptions"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <!-- <div class="form-dropdown-picker-option">
      <div
        class="form-pick-option"
        *ngIf="
          selectedOption?.value === 'avc' ||
          selectedOption?.value === 'thrombolyse' ||
          selectedOption?.value === 'avis_thrombectomie'
        "
      >
        <label>
          <input
            type="radio"
            name="toggle"
            value="true"
            [checked]="
              selectedOption?.symptome?.value?.length > 10 ||
              selectedOption?.symptome?.value === true ||
              selectedOption?.symptome?.value === 'true'
            "
            (change)="onRadioChange(true)"
          />
          Oui
        </label>
        <label *ngIf="selectedOption?.value !== 'avc'">
          <input
            type="radio"
            name="toggle"
            value="non-renseigne"
            [checked]="
              selectedOption?.symptome === undefined ||
              selectedOption?.symptome?.value === null
            "
            (change)="onRadioChange(null)"
          />
          Non Renseigné
        </label>
        <label>
          <input
            type="radio"
            name="toggle"
            value="false"
            [checked]="
              selectedOption?.symptome?.value === false ||
              selectedOption?.symptome?.value === 'false'
            "
            (change)="onRadioChange(false)"
          />
          Non
        </label>
      </div>

      <div
        class="form-dropdown-time-container"
        *ngIf="checkIfDateTimePickShow()"
      >
        <mat-icon class="input-icon" matPrefix> schedule </mat-icon>
        <input
          type="datetime-local"
          (change)="onDateTimeChange($event)"
          [value]="convertDateTime(selectedOption?.symptome?.value)"
        />
      </div>
    </div> -->
  </div>

  <div class="form-options-white-card-container">
    <div
      *ngFor="let symptome of symptoms"
      class="form-options-item-container"
      (click)="onSymptomeClick(symptome)"
    >
      <div class="form-options-item-name-date">
        <span class="form-options-item-name">
          {{
            symptome.title === "Thrombolyse" && symptome.value === false
              ? "Pas de thrombolyse"
              : symptome.title === "Avis thrombectomie" &&
                symptome.value === false
              ? "Pas d'avis thrombectomie"
              : symptome?.title
          }}
        </span>
        <span class="form-options-item-name">
          {{
            symptome.title === "Thrombolyse" && symptome.value === false
              ? ""
              : symptome.title === "Avis thrombectomie" &&
                symptome.value === false
              ? ""
              : symptome.title === "Thrombolyse" && symptome.value.length > 10
              ? "effectué le " + symptome.value
              : symptome.title === "Avis thrombectomie" &&
                symptome.value.length > 10
              ? "effectué le " + symptome.value
              : symptome.value === true || symptome.value === "true"
              ? "Oui"
              : symptome.value === false || symptome.value === "false"
              ? "Non"
              : "le " + symptome.value
          }}
        </span>
      </div>
      <span
        class="form-options-item-renseigner"
        style="width: 300px; text-align: start"
      >
        {{
          symptome?.isAuto == true
            ? symptome?.user + " le " + symptome?.dateRenseignement
            : "Renseigné par " +
              symptome?.user +
              " le " +
              symptome?.dateRenseignement
        }}
      </span>
    </div>
  </div>

  <div class="form-balise-nri-container">
    <div class="form-balise-nri-dropdown-container">
      <div class="form-dropdown">
        <mat-icon class="input-icon" matPrefix> location_on </mat-icon>
        <mat-select
          class="form-dropdown-select"
          placeholder="Hôpital lié"
          appearance="fill"
          (selectionChange)="onChangeSelectedNRI($event)"
          [value]="baliseId ? null : selectedNri"
          [disabled]="baliseId"
        >
          <mat-option *ngFor="let nri of nris" [value]="nri?.id">
            {{ nri?.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <text-button
      *ngIf="!baliseId"
      text="Associer une balise"
      [outlined]="false"
      icon="dashboard"
      (click)="associerUneBalise()"
    ></text-button>
    <text-button
      *ngIf="baliseId"
      text="Dissocier une balise"
      [outlined]="true"
      icon="dashboard"
      (click)="dissocierFromBalise()"
    ></text-button>
    <text-button
      *ngIf="baliseId"
      text="Voir Le Transfert En Cours"
      icon="visibility"
      (click)="showCurrentTransfert(1)"
    ></text-button>
  </div>

  <div class="form-actions-buttons-container">
    <text-button text="Enregistrer" (click)="updatePatient()"></text-button>
    <text-button
      text="Annuler"
      [outlined]="true"
      (click)="annule()"
    ></text-button>
  </div>
</div>
