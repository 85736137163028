export const formDropDownoptions: any[] = [
  {
    name: 'Début des symptômes',
    value: 'debut_symptome',
  },
  {
    name: 'Arrivée urgences',
    value: 'arrivee_urgence',
  },
  {
    name: 'Prise de sang',
    value: 'prise_de_sang',
  },
  {
    name: "Dernier plan d'imagerie",
    value: 'dernier_plan_imagerie',
  },
  {
    name: 'AVC',
    value: 'avc',
  },
  {
    name: 'Thrombolyse',
    value: 'thrombolyse',
  },
  {
    name: 'Avis thrombectomie',
    value: 'avis_thrombectomie',
  },
  {
    name: 'Début de transfert',
    value: 'debut_de_transfert',
  },
  {
    name: 'Arrivée UNV de recours',
    value: 'arrivee_unv_de_recours',
  },
  {
    name: 'Début thrombectomie',
    value: 'debut_thrombectomie',
  },
  {
    name: 'Fin thrombectomie',
    value: 'fin_thrombectomie',
  },
];
