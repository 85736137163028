import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from './../../services/notification.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.css'],
})
export class NotificationCardComponent implements OnInit {
  @Input() notification: any;

  constructor(
    private notificationService: NotificationService,
    public router: Router,
  ) {}

  ngOnInit(): void {}

  markAsRead() {
    this.notificationService.markOneNotificationAsRead(this.notification.id).subscribe(
      (result: any) => {
        if (result.affected === 1) {
          this.notification.read = true;
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  formatNotificationTime(time: any) {
    return moment(time ?? '').fromNow();
  }
  redirectToBalise(n: any) {
    if (n.baliseId) {
      this.router.navigate(['/home/balises'], {
        queryParams: {
          id: n.baliseId ?? -1,
        },
      });
    }
  }
}
