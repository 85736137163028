import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const AUTHORIZATION_KEY = localStorage.getItem('token');
    return next.handle(
      httpRequest.clone({
        setHeaders: { Authorization: `bearer ${AUTHORIZATION_KEY}` },
      }),
    );
  }
}
