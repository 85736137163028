import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(private http: HttpClient) {}

  getPatients() {
    return this.http.get<any>('https://romain.care/api/patient/hospital');
  }

  getPatient(id: string) {
    return this.http.get<any>(`https://romain.care/api/patient/${id}`);
  }

  addPatient(patient: any) {
    return this.http.post('https://romain.care/api/patient', patient);
  }

  updatePatient(id: string, patient: any) {
    return this.http.patch(`https://romain.care/api/patient/${id}`, patient);
  }

  deletePatient(id: string) {
    return this.http.delete(`https://romain.care/api/patient/${id}`);
  }

  getNris() {
    return this.http.get<any>('https://romain.care/api/hospital');
  }

  downloadDossier(id: string) {
    return this.http.get(`https://romain.care/api/patient/form/pdf/${id}`, {
      responseType: 'blob',
    });
  }

  assignNri(id: string, nriId: string) {
    return this.http.patch(`https://romain.care/api/patient/${id}`, {
      nriId: nriId,
    });
  }

  assignBalise(patientId: string, baliseId: string, description: string) {
    return this.http.patch(`https://romain.care/api/patient/${patientId}/balise/${baliseId}`, {
      description: description,
    });
  }

  dissociateBalise(baliseId: string) {
    return this.http.patch(`https://romain.care/api/patient/balise/${baliseId}`, {});
  }
}
