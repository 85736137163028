<div class="title-container">
  <h1 mat-dialog-title>{{ data.title }}</h1>
</div>
<div mat-dialog-content class="description-container">
  <p>
    {{ data.description }}
  </p>
</div>
<div mat-dialog-actions class="buttons-container">
  <text-button
    text="Valider"
    [outlined]="false"
    (click)="onCancel()"
  ></text-button>
</div>
