import { PatientAddComponent } from './pages/patient-add/patient-add.component';
import { HomeComponent } from './pages/home/home.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { PatientsComponent } from './pages/patients/patients.component';
import { BalisesComponent } from './pages/balises/balises.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AuthGuard } from './guards/AuthGuard/auth.guard';
import { PatientDetailsComponent } from './pages/patient-details/patient-details.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { SendMailPasswordComponent } from './pages/send-mail-password/send-mail-password.component';
import { TestEcpsComponent } from './pages/test-ecps/test-ecps.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'ecps', component: TestEcpsComponent },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'signup', component: CreateAccountComponent },
  { path: 'sendEmailPassword', component: SendMailPasswordComponent },
  { path: 'reset-password/:v', component: ForgetPasswordComponent },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'balises', component: BalisesComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'patient', component: PatientsComponent },
      { path: 'patient/balise/:baliseId', component: PatientsComponent },
      { path: 'patient/add', component: PatientAddComponent },
      {
        path: 'patient/:id',
        component: PatientDetailsComponent,
      },
      { path: 'contacts', component: ContactsComponent },
      { path: 'notification', component: NotificationComponent },
    ],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
