import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  getCurrentUser() {
    return this.http.get('https://romain.care/api/user/current');
  }
  updateCurrentUser(newUser: any) {
    return this.http.patch('https://romain.care/api/user', newUser);
  }
  forget(data: any) {
    return this.http.post('https://romain.care/api/forget', data);
  }
  changePassword(data: any) {
    return this.http.post('https://romain.care/api/auth/changePassword', data);
  }
  getEvents() {
    return this.http.get('https://romain.care/api/hospital/calendar');
  }
  addEvent(event: any) {
    return this.http.post('https://romain.care/api/hospital/calendar', event);
  }
  deleteEvent(id: any) {
    return this.http.delete('https://romain.care/api/hospital/calendar/' + id);
  }
  getEventById(id: any) {
    return this.http.get('https://romain.care/api/hospital/calendar/' + id);
  }
  updateEvent(id: any, data: any) {
    data.conflict = 'delete';
    return this.http.post('https://romain.care/api/hospital/calendar/', data);
  }
}
