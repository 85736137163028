<button
  [id]="id"
  [ngClass]="class"
  [ngStyle]="{
    'padding-left': paddingLeft,
    'padding-right': paddingRight,
    'padding-top': paddingTop,
    'padding-bottom': paddingBottom,
    'border-radius': borderRadius
  }"
>
  <mat-icon
    *ngIf="icon !== 'ecps'"
    [ngClass]="'icon'"
    [id]="iconColor"
    inline
    >{{ icon }}</mat-icon
  >
  <img
    *ngIf="icon === 'ecps'"
    src="../../../assets/ecpslogo.png"
    style="width: 24px"
    alt=""
  />
  {{ text }}
</button>
