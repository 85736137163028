import { PatientService } from './../../services/patient.service';
import { Component } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent {
  patients: any[] = [];
  searchedPatients: any[] = [];
  loading: boolean = true;
  search: string = '';
  baliseId: any = this.activatedRoute.snapshot.paramMap.get('baliseId');

  constructor(
    public dialog: MatDialog,
    private patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.patientService.getPatients().subscribe((data) => {
      const patientFormated = data.map((patient: any) => {
        return {
          ...patient,
          birthday: moment(patient.birthday).format('DD/MM/YYYY'),
          created: moment(patient.created).format('DD/MM/YYYY'),
        };
      });

      this.patients = [...this.patients, ...patientFormated];
      this.searchedPatients = [...this.searchedPatients, ...patientFormated];
      this.loading = false;
    });
  }

  searchPatient() {
    if (this.search === '') {
      this.searchedPatients = [...this.patients];
      return;
    }

    this.searchedPatients = this.patients.filter((patient) => {
      return (
        patient?.firstname?.toLowerCase().includes(this.search.toLowerCase()) ||
        patient?.lastname?.toLowerCase().includes(this.search.toLowerCase()) ||
        patient?.nri?.name?.toLowerCase().includes(this.search.toLowerCase()) ||
        patient?.unv?.name?.toLowerCase().includes(this.search.toLowerCase())
      );
    });
  }

  redirectPatient(patientId: any, patient: any) {
    if (this.baliseId) {
      if (patient.balise === null) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '500px',
          data: {
            title: 'Information',
            message: 'Voulez-vous associer ce patient à cette balise ?',
            actionButton: 'Associer',
            cancelButton: 'Voir le patient',
            action: 'update',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'annuler') {
            this.router.navigate(['/home/patient/' + patientId]);
          } else if (result === 'update') {
            this.associerToBalise(patientId);
          }
        });
      } else {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '500px',
          data: {
            title: 'ATTENTION',
            message:
              'Ce patient est déjà affecté à une autre balise. Souhaitez-vous modifier son affectation ? ',
            actionButton: 'VALIDER',
            cancelButton: 'Annuler',
            action: 'update',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'annuler') {
            this.router.navigate(['/home/patient/' + patientId]);
          } else if (result === 'update') {
            this.patientService.dissociateBalise(patient.balise.id).subscribe((response) => {
              console.log('response : ', response);
              this.loading = false;
              this.associerToBalise(patientId);
            });
          }
        });
      }
    } else {
      this.router.navigate(['/home/patient/' + patientId]);
    }
  }

  associerToBalise(patientId: any) {
    this.patientService.assignBalise(patientId, this.baliseId, '').subscribe((response) => {
      this.loading = false;
      this.router.navigate(['/home/patient']);
    });
  }
}
