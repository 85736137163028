<div *ngIf="!areYouSure" class="title-container">
  <h1>Associer Une Balise</h1>
</div>

<div *ngIf="areYouSure">
  <div class="title-container">
    <h1 mat-dialog-title>ATTENTION</h1>
  </div>
  <div mat-dialog-content class="description-container">
    <p>
      La balise sélectionnée est déjà associée à un patient. Souhaitez-vous
      modifier son affectation ?
    </p>
  </div>
</div>

<div *ngIf="!areYouSure">
  <div mat-dialog-content class="description-container">
    <div class="form-balise-nri-dropdown-container">
      <div class="form-dropdown">
        <mat-icon class="input-icon" matPrefix> dashboard </mat-icon>
        <mat-select
          class="form-dropdown-select"
          placeholder="Balises"
          appearance="fill"
          (selectionChange)="onChangeBalise($event)"
        >
          <mat-option *ngFor="let balise of data.balises" [value]="balise?.id">
            {{ balise?.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <label *ngIf="data.balises.length <= 1" class="form-dropdown-label"
      >Aucune balise disponible</label
    >
    <textarea
      [(ngModel)]="description"
      class="form-textarea"
      placeholder="Description"
      matInput
    ></textarea>
  </div>
  <div mat-dialog-actions class="buttons-container">
    <!-- <button mat-button color="primary" (click)="onAssocier()">Valider</button>
    <button mat-button color="warn" (click)="onCancel()">Annuler</button> -->
    <div style="margin-right: 30px">
      <text-button
        text="Valider"
        [outlined]="false"
        (click)="onAssocier()"
      ></text-button>
    </div>

    <text-button
      text="Supprimer"
      [outlined]="true"
      (click)="onCancel()"
    ></text-button>
  </div>
</div>

<div>
  <div mat-dialog-actions class="buttons-container" *ngIf="areYouSure">
    <!-- <button mat-button color="primary" (click)="onAssocier()">Valider</button>
    <button mat-button color="warn" (click)="onCancel()">Annuler</button> -->
    <div style="margin-right: 30px">
      <text-button
        text="VALIDER"
        [outlined]="false"
        (click)="sure()"
      ></text-button>
    </div>

    <text-button
      text="Annuler"
      [outlined]="true"
      (click)="onCancel()"
    ></text-button>
  </div>
</div>
