<!-- <div [ngClass]="'sidebar'">
  <a routerLink="/balises" [ngClass]="'color'">Balises</a>
  <a routerLink="/patients" [ngClass]="'color'">Patients</a>
  <a routerLink="/contacts" [ngClass]="'color'">Contacts</a>
  <a routerLink="/profile" [ngClass]="'color'">profil</a>
</div> -->
<div class="sidebar">
  <mat-drawer #drawer class="side-bar-bg-color" mode="side" opened>
    <mat-nav-list class="d-flex flex-column gap-3">
      <a
        mat-list-item
        routerLinkActive="text-light"
        routerLink="balises"
        class="text-light"
      >
        <div class="d-flex flex-row justify-content-start text-light gap-2">
          <mat-icon>dashboard</mat-icon>
          <div *ngIf="showFullButtons"><span class="px"></span>Balises</div>
        </div>
      </a>
      <div class="line"></div>
      <a
        mat-list-item
        routerLinkActive="text-light"
        routerLink="patient"
        class="text-light"
      >
        <div class="d-flex flex-row justify-content-start text-light gap-2">
          <mat-icon>fact_check</mat-icon>
          <div *ngIf="showFullButtons"><span class="px"></span>Patients</div>
        </div>
      </a>
      <div class="line"></div>
      <a
        mat-list-item
        routerLinkActive="text-light"
        routerLink="contacts"
        class="text-light"
      >
        <div class="d-flex flex-row justify-content-start text-light gap-2">
          <mat-icon>contact_page</mat-icon>
          <div *ngIf="showFullButtons"><span class="px"></span>Contacts</div>
        </div>
      </a>
      <div class="line"></div>
      <a
        mat-list-item
        routerLinkActive="text-light"
        routerLink="profile"
        class="text-light"
      >
        <div class="d-flex flex-row justify-content-start text-light gap-2">
          <mat-icon>person</mat-icon>
          <div *ngIf="showFullButtons"><span class="px"></span>Profil</div>
        </div>
      </a>
    </mat-nav-list>
  </mat-drawer>
</div>
