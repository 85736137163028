import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-error-dialog',
  templateUrl: './patient-error-dialog.component.html',
  styleUrls: ['./patient-error-dialog.component.scss'],
})
export class PatientErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PatientErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onCancel() {
    this.dialogRef.close('cancel');
  }
}
